import * as React from "react";
import Image from 'next/image'
import {useRouter} from 'next/router'
import NextLink from "next/link";
import Link from "next/link";
import {useForm} from "react-hook-form";
import axios from "axios";
import {ButtonPink, ButtonTransparent} from "@/components/showcase/_controls";
import LandingAccordion from "@/components/showcase/LandingAccordion";
import {ModalRequestCall} from "@/components/showcase/_modals";
import {Swiper, SwiperSlide} from "swiper/react";
import 'swiper/swiper.min.css';
import 'swiper/swiper-bundle.min.css'
import {styled} from "@mui/material/styles";
import clsx from "clsx";
import useMediaQuery from '@mui/material/useMediaQuery';
import OutlinedInput from "@mui/material/OutlinedInput";
import InputAdornment from "@mui/material/InputAdornment";
import Img1 from '../../../public/assets/showcase/images/landing-slide-1-alt.webp';
import IconFlaticon from "@/components/IconFlaticon";
//assets/showcase/images/landing-slide-1-alt.webp


export const SwiperLanding = styled(Swiper)(({ theme }) => ({
    width: '1300px',
    marginLeft: '-50px !important',
    paddingLeft: '0rem !important',
    paddingBottom: '3rem !important',
    // [theme.breakpoints.down('lg')]: {
    //     paddingLeft: '1rem !important',
    // },
    // [theme.breakpoints.up('desktop-lg')]: {
    //     '--paddingLeft1': 'calc(100% - 128rem)',
    //     '--paddingLeft2': 'calc(var(--paddingLeft1) / 2)',
    //     paddingLeft: 'var(--paddingLeft2) !important',
    // },
    '& .swiper-slide': {
        opacity: 0.45
    },
    '& .swiper-slide-active': {
        opacity: 1
    }
}));

function LandingTextCheck({text}){
    return(
        <div className='flex flex-row space-x-2.5'>
            <img className='w-4 laptop:w-5' src='/assets/showcase/images/landing-icon-check.svg' alt='CHECK'/>
            <p className='text-sm laptop:text-lg'>
                {text}
            </p>
        </div>
    )
}

function LandingPlaque_1({icon, text, className}){
    return(
        <div className='flex flex-col max-w-sm py-6 laptop:py-15 items-center bg-white rounded-3xl laptop:rounded-5xl shadow-0-16-40'>
            <img className='flex w-18 laptop:w-24 h-18 laptop:h-24 mb-6 laptop:mb-11' src={icon} alt='ICON'/>
            <p className={clsx(className, 'text-sm laptop:text-lg text-center')}>
                {text}
            </p>
        </div>
    )
}

function LandingPlaque_2({icon, title, text}){
    return(
        <div className='max-w-lg laptop:max-w-sm w-full px-5 py-5 laptop:px-8 laptop:py-7.5 items-center bg-white rounded-3xl laptop:rounded-5xl shadow-0-16-40'>
            <img className='w-12 laptop:w-auto mb-3 laptop:mb-5' src={icon} alt='ICON'/>
            <h4 className='mb-1 laptop:mb-2 text-sm laptop:text-lg font-semibold'>{title}</h4>
            <p className='text-sm laptop:text-lg !leading-normal'>{text}</p>
        </div>
    )
}

function LandingReviewsPlaque({icon, title, date, text}){
    return(
        <div className='flex flex-col mt-4 p-7.5 bg-white rounded-3xl laptop:rounded-5xl shadow-0-16-40'>
            <div className='flex flex-row space-x-5 mb-6 items-center'>
                <img className={'h-10 w-10 rounded-full'} src={icon} alt={'PHOTO'}/>
                <div className={'flex flex-col'}>
                    <div className={'text-lg text-black font-bold'}>{title}</div>
                    <div className={'text-sm text-gray-23'}>{date}</div>
                </div>
            </div>
            <div className={'text-lg'}>
                {text}
            </div>
        </div>
    )
}

export default function LandingPage({ device }){
    const router = useRouter()
    const { control, register, handleSubmit, setError, clearErrors, formState: { errors } } = useForm();
    const [isRequestSent, setIsRequestSent] = React.useState(false);
    const [isModalOpen, setIsModalOpen] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const handleModalOpen = () => setIsModalOpen(true);

    const mediaMax = useMediaQuery('(max-width:1279px)')
    const [screenMobile, setScreenMobile] = React.useState(device === 'mobile');

    React.useEffect(()=>{
        setScreenMobile(mediaMax)
    }, [mediaMax])
    
    const onError = (errors, e) => {
        // console.log(errors, e);
        clearErrors()
    }

    function onSubmit(data) {
        const email = data.email.trim()
        if(email.length > 0){
            router.push(`${process.env.NEXT_PUBLIC_DOMAIN_URL}/showcase/start?email=${email}`)
        }else{
            router.push(`${process.env.NEXT_PUBLIC_DOMAIN_URL}/showcase/start`)
        }
    }

    async function sendRequest(data){
        setLoading(true)
        data.sendTo = `${process.env.NEXT_PUBLIC_EMAIL_SEND}`
        data.subject = 'Заявка на консультацию'

        if(!data.name){
            setError('name', { type: 'custom', message: 'Имя не указано!' })
        }

        if(!data.phone){
            setError('phone', { type: 'custom', message: 'Телефон не указан!' })
        }

        if(data.name && data.phone){
            const text = `Заявка с сайта ecommy.ru!`
            const html = `<h1>Заявка на консультацию по аренде магазина!</h1>
            <p>Имя: ${data.name}<p>
            <p>Телефон: ${data.phone}<p>`

            const response = await axios.post(`/api/sendmail`, { email: data.sendTo, subject: data.subject, text, html})
            if(response.data.error){
                setError('all', { type: 'custom', message: response.data.error })
            }
            if(response.data.data){
                setIsRequestSent(true);
                //Почистить форму после отправки может быть?
            }
        }
        setLoading(false);
    }

    return(
        <>
            <div className='z-20 fixed w-full bg-white shadow-0-16-40'>
                <div className='flex flex-row max-w-750 w-full h-15 laptop:h-20 mx-auto px-4 laptop:px-0 justify-between items-center'>
                    <img src={"/assets/showcase/images/logo.svg"} className="w-48 h-16" alt='LOGO'/>
                    {!screenMobile ? (
                        <div className='flex flex-row space-x-4'>
                            <NextLink href='https://wa.clck.bar/79246053995' target='_blank' title='Чат в WhatsApp' className='flex w-10 h-10 items-center justify-center bg-gray-23 hover:bg-purple-1 rounded-full'>
                                <img src='/assets/showcase/images/landing-icon-whatsup.svg' alt='WHATSUP'/>
                            </NextLink>
                            <NextLink href='viber://chat?number=%2B79246053995' target='_blank' title='Чат в Viber' className='flex w-10 h-10 items-center justify-center bg-gray-23 hover:bg-purple-1 rounded-full'>
                                <img src='/assets/showcase/images/landing-icon-viber.svg' alt='VIBER'/>
                            </NextLink>
                            <NextLink href='https://t.me/+79246053995' target='_blank' title='Чат в Telegram' className='flex w-10 h-10 items-center justify-center bg-gray-23 hover:bg-purple-1 rounded-full'>
                                <img src='/assets/showcase/images/landing-icon-telegram.svg' alt='TELEGRAM'/>
                            </NextLink>
                            <NextLink href='mailto:support@ecommy.ru' title='Написать письмо' className='flex w-10 h-10 items-center justify-center bg-gray-23 hover:bg-purple-1 rounded-full'>
                                <img src='/assets/showcase/images/landing-icon-mail.svg' alt='EMAIL'/>
                            </NextLink>
                            <NextLink href={`${process.env.NEXT_PUBLIC_DOMAIN_URL}/login`} className='flex h-10 w-32 !ml-16 items-center justify-center text-white bg-gray-23 hover:bg-purple-1 rounded-lg'>
                                Войти
                            </NextLink>
                        </div>
                    ) : (
                        <div className='flex flex-row space-x-4'>
                            <NextLink href='https://wa.clck.bar/79246053995' target='_blank' title='Чат в WhatsApp' className='hidden tablet:flex w-10 h-10 items-center justify-center bg-gray-23 hover:bg-purple-1 rounded-full'>
                                <img src='/assets/showcase/images/landing-icon-whatsup.svg' alt='WHATSUP'/>
                            </NextLink>
                            <NextLink href='viber://chat?number=%2B79246053995' target='_blank' title='Чат в Viber' className='hidden tablet:flex w-10 h-10 items-center justify-center bg-gray-23 hover:bg-purple-1 rounded-full'>
                                <img src='/assets/showcase/images/landing-icon-viber.svg' alt='VIBER'/>
                            </NextLink>
                            <NextLink href='https://t.me/+79246053995' target='_blank' title='Чат в Telegram' className='hidden tablet:flex w-10 h-10 items-center justify-center bg-gray-23 hover:bg-purple-1 rounded-full'>
                                <img src='/assets/showcase/images/landing-icon-telegram.svg' alt='TELEGRAM'/>
                            </NextLink>
                            <NextLink href='mailto:support@ecommy.ru' title='Написать письмо' className='hidden tablet:flex w-10 h-10 items-center justify-center bg-gray-23 hover:bg-purple-1 rounded-full'>
                                <img src='/assets/showcase/images/landing-icon-mail.svg' alt='EMAIL'/>
                            </NextLink>
                            <NextLink href="/login" className='flex w-10 h-10 items-center justify-center bg-gray-23 hover:bg-purple-1 rounded-full'>
                                <IconFlaticon className={'flex w-9 h-9 items-center justify-center text-xl text-white rounded-full'} name={'rr-user'}/>
                            </NextLink>
                        </div>
                    )}
                </div>
            </div>
            <div className='w-full bg-white-7'>
                <div className='flex flex-col w-full max-w-750 min-h-screen mx-auto'>
                    {/*<div className='flex flex-col-reverse laptop:flex-row justify-between'>*/}
                    {/*    <div className='z-10 relative flex flex-col max-w-xl -mt-32 laptop:mt-62'>*/}
                    {/*        <h1 className='mb-5 laptop:mb-10 mx-auto laptop:mx-0 !text-xl laptop:!text-40px !font-bold'>Интернет-магазин в аренду</h1>*/}
                    {/*        <p className='mb-4 laptop:mb-8 px-8 laptop:px-0 text-center laptop:text-left text-sm laptop:text-lg'>Современный интернет-магазин с профессиональной командой разработчиков</p>*/}
                    {/*        /!*<div className='mx-auto laptop:mx-0 pr-4 laptop:pr-0 pl-5 laptop:pl-4 pt-1 laptop:pt-1.5 pb-2 laptop:pb-2.5 text-sm laptop:text-lg font-semibold bg-contain bg-no-repeat bg-[url("/assets/showcase/images/landing-price-highlight-1.svg")]'>Всего 2500 руб. в месяц!</div>*!/*/}
                    {/*        <p className='mb-5 laptop:mb-10 px-8 laptop:px-0 text-center laptop:text-left text-sm laptop:text-lg'>Интернет-магазин в аренду — отличное решение, если хотите попробовать онлайн-торговлю без риска огромных вложений. Идеально подходит для предпринимателей микро-, малого или среднего бизнеса, а также всем, кто продает через соцсети.</p>*/}
                    {/*        <div className='flex flex-col laptop:flex-row px-5 laptop:px-0 space-y-4 laptop:space-y-0 space-x-0 laptop:space-x-6'>*/}
                    {/*            <ButtonPink href={`${process.env.NEXT_PUBLIC_DOMAIN_URL}/showcase/start`} component={NextLink} className='py-3 laptop:py-4 px-18 text-sm laptop:text-lg bg-gradient-to-r from-pink-1 hover:from-purple-1 to-purple-1 hover:to-purple-1 rounded-2xl'>Создать магазин</ButtonPink>*/}
                    {/*            <ButtonTransparent href={'https://topshop.ecommy.ru'} target={'_blank'} className='py-3 laptop:py-4 px-10 text-sm laptop:text-lg text-black hover:text-white bg-white hover:bg-purple-1 rounded-2xl shadow-0-16-40'>Посмотреть демо</ButtonTransparent>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*    <div className='-mt-36 laptop:mt-0'>*/}
                    {/*        <img className='w-full' src='/assets/showcase/images/landing-slide-1.webp' alt='IMAGE-1'/>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    <div className='flex flex-col'>
                        <div className='w-full min-h-[7.5rem] laptop:min-h-[28.125rem] mt-22 laptop:mt-36'>
                            <Image 
                                src={Img1}
                                alt="Интернет-магазин в аренду"
                                quality={100}
                                sizes="100vw"
                                style={{
                                  width: '100%',
                                  height: 'auto',
                                }}
                            />
                        </div>
                        <div className='flex flex-col items-center justify-center'>
                            <h1 className='mb-6 laptop:mb-8 px-4 text-center !text-3xl laptop:!text-6xl !font-black'>
                                Интернет-магазин <b className='block tablet:hidden'/> <span className={'text-pink-7'}>в аренду</span>
                            </h1>
                            <p className='mb-10 laptop:mb-15 px-9 text-center text-base laptop:text-xl text-[#8888A2] laptop:text-[#2E2E2E] font-[350]'>
                                Начните продавать в интернете уже сегодня. Все инструменты для старта вашего онлайн-бизнеса.
                            </p>
                            {screenMobile && (
                            <div className='w-full px-5'>
                                <ButtonPink href={`${process.env.NEXT_PUBLIC_DOMAIN_URL}/showcase/start`} component={NextLink} className='w-full py-3.5 px-18 text-base bg-gradient-to-r from-pink-1 hover:from-purple-1 to-purple-1 hover:to-purple-1 rounded-xl'>Создать магазин</ButtonPink>
                            </div>
                            )}
                            {!screenMobile && (
                                <form noValidate onSubmit={handleSubmit(onSubmit, onError)}>
                                    <OutlinedInput
                                        sx={{
                                            "& fieldset": { border: 'none' },
                                        }}
                                        {...register("email")}
                                        error={!!errors.email}
                                        fullWidth
                                        name="email"
                                        id="email"
                                        autoComplete="email"
                                        placeholder={'Ваш e-mail'}
                                        className="w-160 p-2 bg-white rounded-2xl text-lg shadow-0-16-40"
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <ButtonPink className='w-full py-4 px-8 text-lg bg-gradient-to-r from-pink-1 hover:from-purple-1 to-purple-1 hover:to-purple-1 rounded-xl' type='submit'>Создать магазин</ButtonPink>
                                            </InputAdornment>
                                        }
                                    />
                                </form>
                            )}
                        </div>
                    </div>

                    <div className='w-full mt-0 laptop:mt-24 px-5 laptop:px-0 bg-transparent laptop:bg-white laptop:rounded-5xl laptop:shadow-0-16-40'>
                        <h2 className='mt-18 text-xl laptop:text-40px text-center font-bold'>Настоящий интернет-магазин</h2>
                        <div className='grid grid-cols-1 laptop:grid-cols-3 gap-y-10 laptop:gap-y-0 gap-x-0 laptop:gap-x-16 w-full laptop:w-auto my-8 laptop:my-18 mx-0 laptop:mx-20 px-10 laptop:px-0 py-10 laptop:py-0 bg-white laptop:bg-transparent rounded-3xl laptop:rounded-none shadow-0-16-40 laptop:shadow-none'>
                            <div className='flex flex-col max-w-xs items-center'>
                                <img className='flex w-18 laptop:w-24 h-18 laptop:h-24 mb-7 laptop:mb-11' src='/assets/showcase/images/landing-icon-1.svg' alt='ICON-1'/>
                                <p className='text-sm laptop:text-lg text-center'>
                                    Создание категорий и подкатегорий товаров, разделы и сортировка по разделам.
                                </p>
                            </div>
                            <div className='flex flex-col max-w-xs items-center'>
                                <img className='flex w-18 laptop:w-24 h-18 laptop:h-24 mb-7 laptop:mb-11' src='/assets/showcase/images/landing-icon-2.svg' alt='ICON-1'/>
                                <p className='text-sm laptop:text-lg text-center px-4'>
                                    Возможность запускать акции и делать скидки на выбранный товар.
                                </p>
                            </div>
                            <div className='flex flex-col max-w-xs items-center'>
                                <img className='flex w-18 laptop:w-24 h-18 laptop:h-24 mb-7 laptop:mb-11' src='/assets/showcase/images/landing-icon-3.svg' alt='ICON-1'/>
                                <p className='text-sm laptop:text-lg text-center'>
                                    Фильтры товара в магазине по популярности, стоимости и наличию скидок.
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className='flex flex-col-reverse laptop:flex-row mt-8 laptop:mt-25 justify-between'>
                        <div className='flex flex-col max-w-xl -mt-6 laptop:mt-32 pl-5 laptop:pl-0 pr-5 laptop:pr-16 space-y-3.5 laptop:space-y-5'>
                            <h3 className='hidden laptop:block mb-5 text-xl laptop:text-40px font-bold'>В вашем стиле</h3>
                            <p className='text-center laptop:text-left text-sm laptop:text-lg'>Выберите адрес будущего магазина, указав его при создании.</p>
                            <p className='text-center laptop:text-left text-sm laptop:text-lg'>Уже есть собственный домен? Мы можем подключить магазин к нему!</p>
                            <p className='text-center laptop:text-left text-sm laptop:text-lg'>Загрузите свой логотип и баннер, выберите цвета магазина из готовых шаблонов.</p>
                        </div>
                        <div>
                            <div className='block laptop:hidden mb-2.5 text-center text-xl laptop:text-40px font-bold'>В вашем стиле</div>
                            <img className='w-full' src='/assets/showcase/images/landing-slide-2.webp' alt='IMAGE-1'/>
                        </div>
                    </div>

                    <div className='flex flex-col justify-center'>
                        <h2 className='mt-18 laptop:mt-22 text-xl laptop:text-40px text-center font-bold'>В ногу с трендами</h2>
                        <div className='grid grid-cols-1 laptop:grid-cols-3 gap-y-5 laptop:gap-y-0 gap-x-0 laptop:gap-x-8 mt-6 laptop:mt-20 mb-5 laptop:mb-15 px-5 laptop:px-0'>
                            <LandingPlaque_1
                                className={'px-5'}
                                icon={'/assets/showcase/images/landing-icon-4.svg'}
                                text={'Очень быстрая работа сайта, потому что используются современные технологии.'}
                            />
                            <LandingPlaque_1
                                className={'px-7'}
                                icon={'/assets/showcase/images/landing-icon-5.svg'}
                                text={'Адаптация под разные устройства: телефон, планшет, компьютер, ноутбук.'}
                            />
                            <LandingPlaque_1
                                className={'px-5'}
                                icon={'/assets/showcase/images/landing-icon-6.svg'}
                                text={'Постоянные обновления с учетом современных трендов и потребностей онлайн-торговли.'}
                            />
                        </div>
                        <div className='flex flex-col laptop:flex-row w-full px-5 laptop:px-0 justify-center space-y-4 laptop:space-y-0 space-x-0 laptop:space-x-4'>
                            <ButtonPink href={`${process.env.NEXT_PUBLIC_DOMAIN_URL}/showcase/start`} component={NextLink} className='py-3 laptop:py-4 px-18 text-sm laptop:text-lg bg-gradient-to-r from-pink-1 hover:from-purple-1 to-purple-1 hover:to-purple-1 rounded-2xl'>Создать магазин</ButtonPink>
                            <ButtonTransparent href={'https://topshop.ecommy.ru'} target={'_blank'} className='py-3 laptop:py-4 px-10 text-sm laptop:text-lg text-black hover:text-white bg-white hover:bg-purple-1 rounded-2xl shadow-0-16-40'>Посмотреть демо</ButtonTransparent>
                        </div>
                    </div>

                    {/*<div className='hidden laptop:flex flex-row mt-25'>*/}
                    {/*    <div className='max-w-lg w-full'>*/}
                    {/*        <img className='w-full' src='/assets/showcase/images/landing-slide-3.webp' alt='IMAGE-1'/>*/}
                    {/*    </div>*/}
                    {/*    <div className='flex flex-col max-w-3xl max-h-124 w-full -ml-13 mt-2 pl-25 pt-18 space-y-4 bg-white rounded-3xl laptop:rounded-5xl shadow-0-16-40'>*/}
                    {/*        <div className='flex flex-col mb-4 text-xl laptop:text-40px font-bold leading-tight'><span>Легко продавать,</span><span>легко покупать</span></div>*/}
                    {/*        <div className='flex flex-row items-center'>*/}
                    {/*            <div className='h-2.5 w-2.5 mr-5 bg-pink-1 rounded-full'/>*/}
                    {/*            <div className='max-w-md text-sm laptop:text-lg leading-snug'>Покупка в интернет-магазине совершается без регистрации</div>*/}
                    {/*        </div>*/}
                    {/*        <div className='flex flex-row items-center'>*/}
                    {/*            <div className='h-2.5 w-2.5 mr-5 bg-pink-1 rounded-full'/>*/}
                    {/*            <div className='max-w-md text-sm laptop:text-lg leading-snug'>Работаем с физлицами и юрлицами, нет требований к статусу продавца.</div>*/}
                    {/*        </div>*/}
                    {/*        <div className='flex flex-row items-center'>*/}
                    {/*            <div className='h-2.5 w-2.5 mr-5 bg-pink-1 rounded-full'/>*/}
                    {/*            <div className='max-w-md text-sm laptop:text-lg leading-snug'>Продавайте любые товары в рамках закона РФ и правил сервиса.</div>*/}
                    {/*        </div>*/}
                    {/*    </div>*/}
                    {/*</div>*/}

                    {/*<div className='flex laptop:hidden flex-col mt-10 pb-10 px-5 space-y-6 items-center bg-gray-24'>*/}
                    {/*    <div className='mt-8 mb-2 text-xl text-center font-bold'>Легко продавать, легко покупать</div>*/}
                    {/*    <div className='h-2.5 w-2.5 bg-pink-1 rounded-full'/>*/}
                    {/*    <div className='max-w-md text-sm text-center leading-snug'>Покупка в интернет-магазине совершается без регистрации</div>*/}
                    {/*    <div className='h-2.5 w-2.5 bg-pink-1 rounded-full'/>*/}
                    {/*    <div className='max-w-md text-sm text-center leading-snug'>Работаем с физлицами и юрлицами, нет требований к статусу продавца.</div>*/}
                    {/*    <div className='h-2.5 w-2.5 bg-pink-1 rounded-full'/>*/}
                    {/*    <div className='max-w-md text-sm text-center leading-snug'>Продавайте любые товары в рамках закона РФ и правил сервиса.</div>*/}
                    {/*</div>*/}

                    <div className='flex flex-col mt-10 laptop:mt-25 pb-5 laptop:pb-0 space-y-3.5 laptop:space-y-6'>
                        <div className='flex flex-col laptop:flex-row mx-auto laptop:mx-0 px-5 laptop:px-0 justify-between'>
                            <div className='flex flex-col max-w-2xl mb-7 laptop:mb-0 justify-center'>
                                <h3 className='w-44 laptop:w-auto mx-auto laptop:mx-0 text-center laptop:text-left text-xl laptop:text-40px font-bold'>Легко продавать, легко покупать</h3>
                                <p className='hidden laptop:block mt-6 text-lg'>Покупка в интернет-магазине совершается без регистрации. Работаем с физлицами и юрлицами, нет требований к статусу продавца. Продавайте любые товары в рамках закона РФ и правил сервиса.</p>
                            </div>
                            <LandingPlaque_2
                                title={'Баннеры и акции'}
                                text={'Расскажите покупателям о новинках магазина и выгодных предложениях. Вы можете разместить до пяти баннеров одновременно.'}
                                icon={'/assets/showcase/images/landing-icon-10.png'}
                            />
                        </div>
                        <div className='flex flex-col laptop:flex-row mx-auto laptop:mx-0 px-5 laptop:px-0 space-y-3.5 laptop:space-y-0 justify-between'>
                            <LandingPlaque_2
                                title={'Мессенджеры и соцсети'}
                                text={'Добавьте ссылки на соцсети и мессенджеры, чтобы покупатели смогли на вас подписаться или отправить там сообщение.'}
                                icon={'/assets/showcase/images/landing-icon-11.png'}
                            />
                            <LandingPlaque_2
                                title={'Заказы на почту'}
                                text={'Заказ придет на вашу почту в виде удобной таблицы, где будет ссылка на товар, цена, количество товара и общая стоимость заказа.'}
                                icon={'/assets/showcase/images/landing-icon-12.png'}
                            />
                            <LandingPlaque_2
                                title={'Адрес магазина'}
                                text={'Укажите адрес своего магазина, где можно посмотреть товар оффлайн или забрать его, если вы работаете через самовывоз.'}
                                icon={'/assets/showcase/images/landing-icon-13.png'}
                            />
                        </div>
                    </div>

                    {!screenMobile && (
                        <div className='flex flex-col justify-center'>
                            <h2 className='mt-8 px-12 laptop:px-0 laptop:mt-28 text-xl laptop:text-40px text-center font-bold'>Готовый интернет-магазин за три шага:</h2>
                            <div className='relative flex flex-col laptop:flex-row justify-between laptop:h-88 mt-9 mb-15'>
                                <img className='absolute ml-48 mt-26' src='/assets/showcase/images/landing-path-1.svg' alt='PATH'/>
                                <div className='flex flex-col w-full max-w-sm items-center bg-no-repeat bg-center bg-auto bg-[url("/assets/showcase/images/landing-slide-bg-1.svg")]'>
                                    <div className='flex flex-col w-78 -ml-18 mt-20 pt-9 pb-12 bg-white rounded-3xl laptop:rounded-5xl shadow-0-16-40'>
                                        <img className='h-15 w-15 mb-9' src='/assets/showcase/images/landing-icon-7.svg' alt='ICON-2'/>
                                        <p className='text-sm laptop:text-lg text-center'>Зарегистрируйте магазин</p>
                                    </div>
                                </div>
                                <img className='absolute right-0 mr-48 mt-36' src='/assets/showcase/images/landing-path-2.svg' alt='PATH'/>
                                <div className='flex flex-col w-full max-w-sm items-center bg-no-repeat bg-center bg-auto bg-[url("/assets/showcase/images/landing-slide-bg-2.svg")]'>
                                    <div className='flex flex-col w-78 mt-20 pt-9 pb-12 bg-white rounded-3xl laptop:rounded-5xl shadow-0-16-40'>
                                        <img className='h-15 w-15 mb-9' src='/assets/showcase/images/landing-icon-8.svg' alt='ICON-2'/>
                                        <p className='text-sm laptop:text-lg text-center'>Добавьте в него товары</p>
                                    </div>
                                </div>
                                <div className='flex flex-col w-full max-w-sm items-center bg-no-repeat bg-center bg-auto bg-[url("/assets/showcase/images/landing-slide-bg-3.svg")]'>
                                    <div className='flex flex-col w-78 ml-18 mt-20 pt-9 pb-12 bg-white rounded-3xl laptop:rounded-5xl shadow-0-16-40'>
                                        <img className='h-15 w-15 mb-9' src='/assets/showcase/images/landing-icon-9.svg' alt='ICON-2'/>
                                        <p className='text-sm laptop:text-lg text-center'>Начните продавать</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                    {screenMobile && (
                    <div className='flex flex-col mb-14 justify-center'>
                        <div className='mt-8 px-12 text-xl text-center font-bold'>Готовый интернет-магазин за три шага:</div>
                        <div className='flex flex-col px-5'>
                            <div className='relative flex flex-col w-full'>
                                <div className='z-10 flex flex-col w-60 mt-10 items-center pt-5 pb-7 bg-white rounded-3xl laptop:rounded-5xl shadow-0-16-40'>
                                    <img className='h-11 w-11 mb-5' src='/assets/showcase/images/landing-icon-7.svg' alt='ICON-2'/>
                                    <div className='text-sm laptop:text-lg text-center'>Оставьте заявку на открытие</div>
                                </div>
                                <img className='absolute w-44  ml-26' src='/assets/showcase/images/landing-slide-bg-1.svg' alt='BG'/>
                                <div className='relative flex justify-end items-end'>
                                    <div className='z-10 flex flex-col w-60 mt-10 items-center pt-5 pb-7 bg-white rounded-3xl laptop:rounded-5xl shadow-0-16-40'>
                                        <img className='h-11 w-11 mb-5' src='/assets/showcase/images/landing-icon-8.svg' alt='ICON-2'/>
                                        <div className='text-sm laptop:text-lg text-center'>Добавьте товары в магазин</div>
                                    </div>
                                    <img className='absolute w-44 mr-28' src='/assets/showcase/images/landing-slide-bg-1.svg' alt='BG'/>
                                </div>
                                <div className='z-10 flex flex-col w-60 mt-10 items-center pt-5 pb-7 bg-white rounded-3xl laptop:rounded-5xl shadow-0-16-40'>
                                    <img className='h-11 w-11 mb-5' src='/assets/showcase/images/landing-icon-9.svg' alt='ICON-2'/>
                                    <div className='text-sm laptop:text-lg text-center'>Начните продавать</div>
                                </div>
                                <img className='absolute w-44 ml-28 bottom-0' src='/assets/showcase/images/landing-slide-bg-3.svg' alt='BG'/>
                            </div>
                        </div>
                    </div>
                    )}
                    <div className='relative flex flex-col w-full h-100 laptop:h-134 px-5 items-center bg-no-repeat bg-center bg-cover laptop:bg-contain bg-[url("/assets/showcase/images/landing-slide-bg-1.svg")]'>
                        <h3 className='mt-10 laptop:mt-22 px-5 laptop:px-0 text-xl laptop:text-40px text-center font-bold'>Нужна индивидуальная доработка?</h3>
                        <p className='hidden laptop:block mt-13 text-sm laptop:text-lg text-center font-semibold'>Интеграции с продуктами 1С - Интеграции с маркетплейсами - Инструменты SEO-оптимизации</p>
                        <p className='hidden laptop:block mt-5 text-sm laptop:text-lg text-center font-semibold'>Модули доставки и оплаты - Дополнительные параметры товаров - Персональный дизайн</p>
                        <div className='flex laptop:hidden flex-col mt-5 space-y-5'>
                            <div className='flex flex-row items-center text-sm'>
                                <div className='h-1.5 w-1.5 mr-3 bg-pink-1 rounded-full'/>
                                Интеграции с продуктами 1С
                            </div>
                            <div className='flex flex-row items-center text-sm'>
                                <div className='h-1.5 w-1.5 mr-3 bg-pink-1 rounded-full'/>
                                Интеграции с маркетплейсами
                            </div>
                            <div className='flex flex-row items-center text-sm'>
                                <div className='h-1.5 w-1.5 mr-3 bg-pink-1 rounded-full'/>
                                Инструменты SEO-оптимизации
                            </div>
                            <div className='flex flex-row items-center text-sm'>
                                <div className='h-1.5 w-1.5 mr-3 bg-pink-1 rounded-full'/>
                                Модули доставки и оплаты
                            </div>
                            <div className='flex flex-row items-center text-sm'>
                                <div className='h-1.5 w-1.5 mr-3 bg-pink-1 rounded-full'/>
                                Дополнительные параметры товаров
                            </div>
                            <div className='flex flex-row items-center text-sm'>
                                <div className='h-1.5 w-1.5 mr-3 bg-pink-1 rounded-full'/>
                                Персональный дизайн
                            </div>
                        </div>
                        <ButtonTransparent onClick={handleModalOpen} className='w-full laptop:w-fit mt-6 laptop:mt-13 px-18 py-3 laptop:py-4 text-sm laptop:text-lg text-black hover:text-white bg-white hover:bg-purple-1 rounded-2xl shadow-0-16-40'>Получить консультацию</ButtonTransparent>
                        {!screenMobile && (
                            <>
                                <img className='absolute w-auto left-0 mt-24 -ml-5' src='/assets/showcase/images/landing-icon-ozon.svg' alt='OZON'/>
                                <img className='absolute w-auto right-0 mt-20 -mr-7' src='/assets/showcase/images/landing-icon-1C.svg' alt='1c'/>
                                <img className='absolute w-auto left-13 mt-70' src='/assets/showcase/images/landing-icon-wb.svg' alt='wb'/>
                                <img className='absolute w-auto right-56 mt-78' src='/assets/showcase/images/landing-icon-tinkoff.svg' alt='tinkoff'/>
                                <img className='absolute w-auto right-5 mt-65' src='/assets/showcase/images/landing-icon-cdek.svg' alt='CDEK'/>
                                <img className='absolute w-auto bottom-0 left-60' src='/assets/showcase/images/landing-icon-mv.svg' alt='mv'/>
                                <img className='absolute w-auto bottom-0 -ml-36 -mb-2' src='/assets/showcase/images/landing-icon-yoo.svg' alt='yoo'/>
                                <img className='absolute w-auto bottom-0 ml-64 -mb-10' src='/assets/showcase/images/landing-icon-sb.svg' alt='sber'/>
                            </>
                        )}
                    </div>

                    <div className='flex flex-col w-auto laptop:w-full mx-5 laptop:mx-0 mt-16 laptop:mt-44 px-3 laptop:px-0 items-center bg-white rounded-3xl laptop:rounded-5xl shadow-0-16-40'>
                        <h2 className='hidden laptop:block mt-18 mb-12 text-40px text-center font-bold'>
                            {/*И это все за<span className='px-3 bg-no-repeat bg-[url("/assets/showcase/images/landing-price-highlight-2.svg")]'>2500 руб.</span>в месяц*/}
                            За <span className='text-pink-4'>1190 руб.</span> в месяц вы получаете:
                        </h2>
                        <div className='flex laptop:hidden flex-col mt-8 mb-6 text-xl text-center font-bold'>
                            <div>И это все за<span className='px-3 bg-no-repeat bg-cover bg-[url("/assets/showcase/images/landing-price-highlight-2.svg")]'>1190 руб.</span></div>
                            <div>в месяц</div>
                        </div>
                        <div className='flex flex-col laptop:flex-row space-x-0 laptop:space-x-16 space-y-2 laptop:space-y-0 px-2'>
                            <div className='flex flex-col space-y-2'>
                                <LandingTextCheck text={'Неограниченное количество товаров'}/>
                                <LandingTextCheck text={'Баннерные места на страницы'}/>
                                <LandingTextCheck text={'Настройка скидок на товары'}/>
                                <LandingTextCheck text={'Ссылки на соцсети и мессенджеры'}/>
                                <LandingTextCheck text={'Система управления магазином'}/>
                            </div>
                            <div className='flex flex-col space-y-2'>
                                <LandingTextCheck text={'Настройка цветового оформления'}/>
                                <LandingTextCheck text={'Уникальное доменное имя'}/>
                                <LandingTextCheck text={'SSL-сертификат'}/>
                                <LandingTextCheck text={'Выделенный сервер'}/>
                                <LandingTextCheck text={'Техподдержка и обслуживание'}/>
                            </div>
                        </div>
                        {/*<div className='mb-6 laptop:mb-0 text-sm laptop:text-lg text-center'>В стоимость входит хостинг, обслуживание, хранение данных, техподдержка и добавление нового функционала.</div>*/}
                        {/*<div className='text-sm laptop:text-lg text-center'>От регистрации до начала торговли — один день. Оставьте заявку, чтобы начать продавать в интернете уже сегодня.</div>*/}
                        <ButtonPink href={`${process.env.NEXT_PUBLIC_DOMAIN_URL}/showcase/start`} component={NextLink} className='w-full laptop:w-auto mt-8 laptop:mt-18 mb-3 laptop:mb-12 py-3 laptop:py-4 px-20 text-sm laptop:text-lg bg-gradient-to-r from-pink-1 hover:from-purple-1 to-purple-1 hover:to-purple-1 rounded-2xl'>Создать магазин</ButtonPink>
                    </div>

                    {/*<div className='mt-14 laptop:mt-28 text-xl laptop:text-40px text-center font-bold'>Мнения бизнеса о нас</div>*/}
                    {/*<SwiperLanding*/}
                    {/*    className={'mt-20'}*/}
                    {/*    effect={'coverflow'}*/}
                    {/*    grabCursor={true}*/}
                    {/*    centeredSlides={true}*/}
                    {/*    slidesPerView={3}*/}
                    {/*    // spaceBetween={16}*/}
                    {/*    coverflowEffect={{*/}
                    {/*        rotate: 50,*/}
                    {/*        stretch: 0,*/}
                    {/*        depth: 100,*/}
                    {/*        modifier: 1,*/}
                    {/*        slideShadows: false,*/}
                    {/*    }}*/}
                    {/*    pagination={false}*/}
                    {/*    modules={[EffectCoverflow, Pagination]}*/}
                    {/*>*/}
                    {/*    <SwiperSlide>*/}
                    {/*        <LandingReviewsPlaque*/}
                    {/*            icon={'/assets/showcase/images/landing-icon-11.png'}*/}
                    {/*            title={'Елена Милова'}*/}
                    {/*            text={'Долго хотели открыть интернет-магазин, но затянули, хотя у большинства конкурентов уже есть (кондитерская сфера). Не устраивали то сроки, то цена. По рекомендации присмотрелись к магазину в аренду и не разочаровались! За один рабочий день загрузили товары и дали рекламу. Цена более чем устраивает. Порадовала возможность быстро начать. Спасибо!'}*/}
                    {/*            date={'12 июня 2023'}*/}
                    {/*        />*/}
                    {/*    </SwiperSlide>*/}
                    {/*    <SwiperSlide>*/}
                    {/*        <LandingReviewsPlaque*/}
                    {/*            icon={'/assets/showcase/images/landing-icon-12.png'}*/}
                    {/*            title={'Виктория'}*/}
                    {/*            text={'Раньше продавала через ВК и Телеграмм, в чем тут минусы объяснять не надо.  Со мной сами связались там и предложили попробовать новый сервис « интернет-магазин в аренду». Очень довольна что это настоящий интернет-магазин по сути за такую цену, при чем выгодно брать надолго. А от соцсетей не отказалась, публикую там новинки и акции и сразу даю ссылку на товар. В отличии от сообщений как раньше, сразу могут заказать на сайте без всякой переписки. Плюс пришли клиенты не из ВК.'}*/}
                    {/*            date={'12 июня 2023'}*/}
                    {/*        />*/}
                    {/*    </SwiperSlide>*/}
                    {/*    <SwiperSlide>*/}
                    {/*        <LandingReviewsPlaque*/}
                    {/*            icon={'/assets/showcase/images/landing-icon-13.png'}*/}
                    {/*            title={'Сергей Викторович'}*/}
                    {/*            text={'По факту был опыт, что заказали свой интернет-магазин у одной “студии”, прошло полгода, деньги взяли и не доделали. Поэтому к теме были скептически настроены в компании. А тут партнеры посоветовали, что есть сайт с магазинами в аренду, за месяц плата небольшая, то есть и риск небольшой. В общем, не пожалели ни разу. То, что тут предлагают в аренду, так не у каждой фирмы такой хороший магазин есть. Теперь и не думаем с кем-то другим связываться. Успехов и добра. Сергей'}*/}
                    {/*            date={'12 июня 2023'}*/}
                    {/*        />*/}
                    {/*    </SwiperSlide>*/}
                    {/*    <SwiperSlide>*/}
                    {/*        <LandingReviewsPlaque*/}
                    {/*            icon={'/assets/showcase/images/landing-icon-13.png'}*/}
                    {/*            title={'Денис Мой сад'}*/}
                    {/*            text={'Магазин далеко от центра, часто спрашивают, где можно вас посмотреть в интернете. Бизнес сезонный, офлайн. Решили взять магазин как раз на время активных продаж. Пока очень довольны, видимо подклюимся еще! Очень нравится сотрудничать с Вами, спасибо!'}*/}
                    {/*            date={'12 июня 2023'}*/}
                    {/*        />*/}
                    {/*    </SwiperSlide>*/}
                    {/*</SwiperLanding>*/}

                    <div className='flex flex-col justify-center pb-8 laptop:pb-24'>
                        <h3 className='mt-18 laptop:mt-32 mb-8 laptop:mb-12 text-xl laptop:text-40px text-center font-bold'>Частые вопросы</h3>
                        <LandingAccordion/>
                    </div>
                </div>
            </div>

            <div className='w-full bg-gray-16'>
                <div className='flex flex-col laptop:flex-row max-w-750 w-full mx-auto px-5 laptop:px-0 pt-10 pb-8 justify-between'>
                    <div className='flex flex-col mb-3 laptop:mb-0 space-y-3 laptop:space-y-2.5'>
                        <NextLink href='/' className='text-sm laptop:text-base text-gray-21 hover:text-purple-1'>Создать магазин</NextLink>
                        <div onClick={handleModalOpen} className='text-sm laptop:text-base text-gray-21 hover:text-purple-1 cursor-pointer'>Получить консультацию</div>
                        <Link href='https://topshop.ecommy.ru' target='_blank' className='text-sm laptop:text-base text-gray-21 hover:text-purple-1'>Посмотреть демо</Link>
                    </div>
                    <div className='flex flex-col mb-6 laptop:mb-0 space-y-3 laptop:space-y-2.5'>
                        <NextLink href='/offer' className='text-sm laptop:text-base text-gray-21 hover:text-purple-1'>Пользовательское соглашение (оферта)</NextLink>
                        <NextLink href='/privacy' className='text-sm laptop:text-base text-gray-21 hover:text-purple-1'>Политика конфиденциальности</NextLink>
                        <NextLink href='/price' className='text-sm laptop:text-base text-gray-21 hover:text-purple-1'>Прайс-лист</NextLink>
                    </div>
                    <div className='flex flex-col space-y-2.5 laptop:items-end'>
                        <div className='mb-2.5 text-sm laptop:text-base text-gray-15 font-semibold uppercase'>Поддержка клиентов:</div>
                        <div className='flex flex-row space-x-4'>
                            <NextLink href='https://wa.clck.bar/79246053995' target='_blank' className='flex w-10 h-10 items-center justify-center bg-gray-23 hover:bg-purple-1 rounded-full'>
                                <img src='/assets/showcase/images/landing-icon-whatsup.svg' alt='WHATSUP'/>
                            </NextLink>
                            <NextLink href='viber://chat?number=%2B79246053995' target='_blank' className='flex w-10 h-10 items-center justify-center bg-gray-23 hover:bg-purple-1 rounded-full'>
                                <img src='/assets/showcase/images/landing-icon-viber.svg' alt='VIBER'/>
                            </NextLink>
                            <NextLink href='https://t.me/+79246053995' target='_blank' className='flex w-10 h-10 items-center justify-center bg-gray-23 hover:bg-purple-1 rounded-full'>
                                <img src='/assets/showcase/images/landing-icon-telegram.svg' alt='TELEGRAM'/>
                            </NextLink>
                            <NextLink href='mailto:support@ecommy.ru' className='flex w-10 h-10 items-center justify-center bg-gray-23 hover:bg-purple-1 rounded-full'>
                                <img src='/assets/showcase/images/landing-icon-mail.svg' alt='EMAIL'/>
                            </NextLink>
                        </div>
                    </div>
                </div>
            </div>
            <div className='w-full bg-gray-16 border-solid border-0 border-t-0 laptop:border-t-2 border-gray-17'>
                <div className='flex flex-col laptop:flex-row max-w-750 w-full laptop:h-18 mx-auto justify-between items-center'>
                    <div className='flex flex-row w-full laptop:w-auto px-5 laptop:px-0 items-center text-sm laptop:text-base text-gray-20 justify-between border-solid border-0 border-b laptop:border-b-0 border-gray-17'>
                        <img src={"/assets/showcase/images/logo.svg"} className="w-48 h-16 mr-5" alt='LOGO'/>
                        2024
                    </div>
                    <Link href='https://clapps.ru' target='_blank' className='flex flex-col laptop:flex-row w-full laptop:w-auto py-5 laptop:py-0 px-5 laptop:px-0 items-start laptop:items-center text-sm laptop:text-base text-gray-19'>
                        Сервис разработан компанией "КЛАППС"
                        <img className='mt-4 laptop:mt-0 ml-0 laptop:ml-3' src='/assets/showcase/images/logo-clapps.svg' alt='CLAPPS'/>
                    </Link>
                </div>
            </div>
            <div className='w-full bg-gray-17'>
                <div className='max-w-750 w-full mx-auto px-5 laptop:px-3 py-5 laptop:py-6 text-left laptop:text-center text-10px laptop:text-xs text-gray-18 text-opacity-50'>
                    <span dangerouslySetInnerHTML={{__html: '<!--noindex-->'}}/>
                        Правообладатель интернет-витрины ecommy.ru: Розиков Альберт Абдукаюмович, ИНН: 381011524127 зарегистрирован в ФНС в качестве налогоплательщика «налога на профессиональный доход» (в дальнейшем НПД), в соответствии с ФЗ от 27.11.2018 №422-ФЗ, получивший статус самозанятого 19.10.2023 г., что подтверждается в справке о постановке на учет самозанятого № 42505830
                    <span dangerouslySetInnerHTML={{__html: '<!--/noindex-->'}}/>
                </div>
            </div>

            <ModalRequestCall
                device={device}
                anchor={'bottom'}
                isOpen={isModalOpen}
                onOpen={handleModalOpen}
                isRequestSent={isRequestSent}
                onClose={() => setIsModalOpen(false)}
                onSubmit={handleSubmit(sendRequest, onError)}
                onLoading={loading}
                register={register}
                errors={errors}
            />
        </>
    )
}