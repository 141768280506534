import * as React from 'react';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';
import clsx from 'clsx';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { ButtonWhiteLoading } from "@/components/showcase/_controls";
import IconFlaticon from "@/components/IconFlaticon";

const generateUUID = () => {
  let
    d = new Date().getTime(),
    d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now() * 1000)) || 0;
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    let r = Math.random() * 16;
    if (d > 0) {
      r = (d + r) % 16 | 0;
      d = Math.floor(d / 16);
    } else {
      r = (d2 + r) % 16 | 0;
      d2 = Math.floor(d2 / 16);
    }
    return (c == 'x' ? r : (r & 0x7 | 0x8)).toString(16);
  });
};

function ChatFormUploadImage(props) {

  const [error, setError] = React.useState(null)

  async function handleChange(e) {
    function readFileAsync(i) {
      return new Promise((resolve, reject) => {
        const file = e.target.files[i];
        if (!file) {
          return;
        }
        const reader = new FileReader();

        reader.onload = () => {
          var image = new Image();
          var width;
          var height;
          image.onload = function(){       
            resolve({
              id: generateUUID(),
              url: `data:${file.type};base64,${btoa(reader.result)}`,
              type: file.type === 'application/pdf' ? 'pdf' : 'image',
              name: file.name,
              size: file.size,
              width: this.width,
              height: this.height
            })
          }
          image.onerror = function() {
            resolve({
              id: generateUUID(),
              url: `data:${file.type};base64,${btoa(reader.result)}`,
              type: file.type === 'application/pdf' ? 'pdf' : 'image',
              name: file.name,
              size: file.size
            })
          }
          image.src = `data:${file.type};base64,${btoa(reader.result)}`;
          
        };

        reader.onerror = reject;

        reader.readAsBinaryString(file);
      });
    }
    let newFiles = [];

    if(e.target.files.length>0){
      // eslint-disable-next-line no-plusplus
      for(let i = 0; i < e.target.files.length; i++){
        const fileOut = e.target.files[i];
        const fileIn = props.images.filter(item => item.name === fileOut.name);
        if(fileIn.length === 0) {
          // eslint-disable-next-line no-await-in-loop
          const newImage = await readFileAsync(i);
          if(props.minWidth && newImage.width && (newImage.width < props.minWidth)){
            setError(`Ошибка загрузки! Размер изображения должен быть не меньше ${props.minWidth}х${props.minHeight} px`)
            props.onChange({ error: true });
            return
          }
          if(props.minHeight && newImage.height && (newImage.height < props.minHeight)){
            setError(`Ошибка загрузки! Размер изображения должен быть не меньше ${props.minWidth}х${props.minHeight} px`)
            props.onChange({ error: true });
            return
          }
          if(newImage.size > props.maxSize){
            setError(`Ошибка загрузки! Размер одного файла не может превышать: ${props.maxLabel}`)
            props.onChange({ error: true });
            return
          }
          newFiles.push(newImage)
        }
      }
    }
    if (props.onChange) {
      if(props.multiple){
        if((newFiles.length + props.images.length + props.countFilesIn) > props.maxFiles){
          setError(`Максимальное количество загружаемых файлов: ${props.maxFiles}`)
          props.onChange({ error: true });
        }else{
          props.onChange({ images: [...newFiles, ...props.images], files: e, error: false });
        }
      }else{
        props.onChange({ images: newFiles, files: e, error: false });
      }
    }
  }

  

  const getAccept = () => {
    let text = 'image/*'
    if(props.buttonType === 1){
      text += ',application/pdf'
    }
    return text
  }

  return (
    <>
      <label htmlFor={`button-file-${props.keyId}`}>
        {props.buttonType === 1 && (
          <Button
            className="h-10 px-6 bg-gray6 hover:bg-gray10 text-sm tablet:text-base font-normal text-black rounded-12 shadow-none hover:shadow-none normal-case"
            variant="contained"
            component="span"
          >
            Загрузить фото
          </Button>
        )}
        {props.buttonType === 2 && (
          <div className={clsx('w-full mb-8 px-10 pt-6 pb-5 bg-white-3 text-center leading-6', props.imagesUploaded ? 'rounded-b-12' : 'rounded-12')}>
              {error && (
                <div className='text-red-1 pb-3'>{error}</div>
              )}
              <Button 
                className='max-w-76 w-full mb-4 px-12 py-3 bg-white hover:bg-gray-26 text-sm tablet:text-base text-black-1 font-normal normal-case rounded-lg shadow-lg hover:shadow-none'
                component="span"
              >
                  Загрузить файл
              </Button>
              <div className='block tablet:flex tablet:flex-col px-2 tablet:px-0 space-x-1 tablet:space-x-0 text-xs tablet:text-sm text-gray-9'>
                  <span>Допускаются только форматы png, svg, webp, jpeg</span>
                  <span>весом не более {props.maxLabel}</span>
              </div>
          </div>
        )}
        {props.buttonType === 3 && (
            <>
              <div className={clsx(error ? 'mb-4' : 'mb-0 tablet:mb-8', 'flex flex-row py-2 tablet:py-4 px-3 tablet:px-7 items-center justify-between bg-white tablet:bg-gradient-to-r from-purple-1 via-pink-1 to-purple-1 laptop:rounded-xl')}>
                <div className='flex flex-row items-center'>
                  <img className='hidden tablet:flex mr-8' src='/assets/showcase/images/no-banner-ph.svg' alt='IMAGE' />
                  <div className='hidden tablet:flex flex-col space-y-1 pr-2'>
                    <div className='text-white text-base font-semibold'>Загружайте рекламные баннеры. Расскажите о ваших акциях, новинках, распродажах</div>
                    <div className='text-white text-sm'>Размер изображения должен быть не меньше 1200х400 px, допустимый формат: png, jpeg, webp</div>
                  </div>
                </div>
                <ButtonWhiteLoading loading={props.loadingUpload} component="span" className='w-full tablet:w-72 p-3 bg-white text-black text-sm tablet:text-base shadow-md hover:brightness-90'>
                  Загрузить баннер
                </ButtonWhiteLoading>
              </div>
              {!error && (
                  <div className='flex tablet:hidden flex-row mb-4 px-3 text-xs text-gray-9 text-center'>
                    Размер изображения должен быть не меньше 1200х400 px, допустимый формат: png, jpeg, webp
                  </div>
              )}
              {error && (
                  <div className={'flex flex-row w-full py-3 mb-4 items-center justify-center text-red-5 bg-white-7 laptop:rounded-xl'}>
                    {error}
                    {/*<div className={'mr-2 mt-1 py-1 cursor-pointer'} onClick={() => setError(false)}>*/}
                    {/*  <IconFlaticon className={'text-2xl text-gray-3'} name={'sr-cross-circle'}></IconFlaticon>*/}
                    {/*</div>*/}
                  </div>
              )}
            </>
        )}
      </label>
      
      <input
        ref={props.inputRef}
        accept={props.accept ? props.accept : getAccept()}
        className="hidden"
        id={`button-file-${props.keyId}`}
        type="file"
        multiple={props.multiple}
        onClick={e => {
          setError(null)
          e.target.value = null
        }}
        onChange={async (e) => {await handleChange(e)}}
      />
    </>
  );
}

export default ChatFormUploadImage;
