import * as React from "react";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import {StyledLink} from "@/components/showcase/_controls";
import {getThemeColors} from "@/components/showcase/_helpers";

export default function BreadcrumbsShop({cat, product, colorScheme}){
    const slugParents = []

    function getSlugParent(c){
        if(c.attributes?.parent?.data){
            const s1 = `${c.attributes.parent.data.attributes.slug}`
            slugParents.push({name: c.attributes.parent.data.attributes.name, slug: s1})
            getSlugParent(c.attributes.parent.data)
        }
    }

    if(product){
        if(cat){
            const s1 = `${cat.attributes.slug}`
            slugParents.push({name: cat.attributes.name, slug: s1})
        }
    }

    if(cat){
        getSlugParent(cat)
        slugParents.reverse()
    }

    if(!cat && !product){
        return (
            <div className="flex flex-row items-center" role="presentation">
                <Breadcrumbs className='text-black leading-tight' aria-label="breadcrumb" separator={'/'}> {/*<NavigateNextIcon fontSize="small" />*/}
                    <StyledLink
                        key="def0"
                        href={'/'}
                        text={'Главная'}
                        ariaLabel={'breadcrumb active'}
                        colorScheme={colorScheme}
                    />
                </Breadcrumbs>
            </div>
        )
    }

    return (
        <div className="flex flex-row items-center" role="presentation">
            <Breadcrumbs className='text-black leading-tight' aria-label="breadcrumb" separator={'/'}>
                <StyledLink
                    key="def1"
                    href={'/'}
                    text={'Главная'}
                    ariaLabel={'breadcrumb active'}
                    colorScheme={colorScheme}
                />
                {slugParents.map((sp, n) => {
                    const slugTemp = slugParents.slice(0, n+1).map((e)=> e.slug).join('/')
                    return (
                        <StyledLink
                            key={n}
                            href={slugTemp}
                            text={sp.name}
                            colorScheme={colorScheme}
                        />
                    )
                })}
                <div style={{color: getThemeColors(colorScheme)}} className={'max-w-24 truncate'} aria-label="breadcrumb active">
                    {product ? product.attributes.name : cat.attributes.name}
                </div>
            </Breadcrumbs>
        </div>
      );
}