import * as React from 'react';
import Link from '../../../../theme/Link';
import IconFlaticon from '@/components/IconFlaticon';
import { getPrice, getCount } from './libPoduct'
import format from 'date-fns/format';
import ru from 'date-fns/locale/ru';

export default function StoreLabel({ product }){

    if(getCount(product) === 0 && getPrice(product) === 0){
        return <></>
    }

    function getRandom(){
        return  Math.floor(Math.random() * 7) + 1
    }

    if(getCount(product) === 0){
        const date = new Date().addDays(getRandom())
        return(
            <div className='leading-none text-xs font-semibold'>
                На складах <span className='text-sm'>{format(date, "dd MMMM",  { locale: ru })}</span>
            </div>
        )
    }


    return(
        <div className='leading-none text-xs font-semibold'>Осталось <span className='text-sm'>{getCount(product)}</span> шт</div>    
    )
}


Date.prototype.addDays = function(days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
}