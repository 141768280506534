import * as React from 'react';
import clsx from 'clsx'
import { useForm  } from "react-hook-form";
import IconButton from '@mui/material/IconButton';
import Drawer from '@mui/material/Drawer';
import InputAdornment from '@mui/material/InputAdornment';
import IconFlaticon from "@/components/IconFlaticon";
import InputBase from '@mui/material/InputBase';
import RatingFilter from '../filter/RatingFilter';
import PriceFilter from '../filter/PriceFilter';
import useUser from '../../../../../lib/useUser';
import {getThemeColors} from "@/components/showcase/_helpers";
import {StyledButton, StyledIconButton} from "@/components/showcase/_controls";
import {styled} from "@mui/material/styles";
import NextLink from "next/link";

export default function FilterHeader({ 
    user,
    cats, activeCat, sortPrice, handleChangeSortPrice, urlPrice, 
    handleChangeFilterPrice,  
    filterPriceStart,
    setFilterPriceStart,
    filterPriceEnd,
    setFilterPriceEnd,
    handleChangeText,
    textName, 
    setTextName,
    colorScheme
}){

    const { control, register, handleSubmit, setError, clearErrors, setValue, formState: { errors } } = useForm();
    const themeColor = getThemeColors(colorScheme ? colorScheme : 0);
    const ThemedOutline = styled('div')({
        borderRadius: 12,
        borderWidth: 2,
        borderStyle: 'solid',
        borderColor: themeColor,
        '&:hover': {
            borderColor: themeColor
        }
    });
    const ThemedDrawerLink = styled('div')({
        '&:hover': {
            color: themeColor
        },
    });

    function getParentCats(cat, catsActive) {
        if(cat){
            const id = cat.attributes?.parent?.data?.id || null
            const data = cat.attributes?.parent?.data || null
            const catT = cats.find((e)=> e.id === cat.id)
            if(id){
                return [...catsActive, catT, ...getParentCats(data, catsActive)]
            }else{
                return [...catsActive, catT]
            }
        }
        return []
    }
    //const catsActive = getParentCats(activeCat, [])
    //catsActive.reverse()

    //const [tempCats, setTempCats] = React.useState(activeCat ? catsActive : [])
    const [tempCats, setTempCats] = React.useState([])
    let slug = `/`

    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    })

    // React.useEffect(() => {
    //     const catsActive = getParentCats(activeCat, [])
    //     catsActive.reverse()
    //     setTempCats(catsActive)
    // }, [activeCat])

    const toggleDrawer = (anchor, open) => (event) => {
        setTempCats([])
        if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
            return
        }
        setState({ ...state, [anchor]: open })
    }

    function setCats(cat, lvl){
        const check = tempCats.find((e)=> e.id === cat.id)
        if(!check){
            if(lvl === 0){
                setTempCats([cat])
            }else{
                setTempCats([...tempCats.slice(0, lvl), cat])
            }
        }
    }

    const GetEl = ({cat, lvl, slug}) => {
        const check = tempCats.find((e)=> e.id === cat.id)
        const child = cat.attributes?.child?.data

        if(child && child.length > 0 ){
            return(
                <ThemedDrawerLink
                    onMouseEnter={()=>setCats(cat, lvl)}
                    className={'flex flex-row items-center justify-between cursor-pointer space-x-1.5 py-2.5 px-4'}
                    style={{color: check && themeColor}}
                >
                    <span>{cat.attributes.name}</span>
                    <IconFlaticon style={{color: check && themeColor}} className={'!mr-m5 text-gray-4'} name='rr-angle-right' />
                </ThemedDrawerLink>
            )
        }

        return(
            <NextLink 
                href={`${slug}`} 
                className='text-black'
                onClick={()=>close(cat, lvl)}
            > 
                <ThemedDrawerLink
                    onMouseEnter={()=>setCats(cat, lvl)}
                    className={'cursor-pointer py-2.5 px-4 rounded-12'}
                    style={{color: check && themeColor}}
                >
                    <span>{cat.attributes.name}</span>
                </ThemedDrawerLink>
            </NextLink>
        )
    }

    function getSlug(cat){
        return cat.attributes.slug
    }

    function close(cat, lvl){
        //setCats(cat, lvl)
        setTempCats([])
        setState({ ...state, ['left']: false })
    }

    async function onSubmit(data){
        setTextName(data.textName)
        handleChangeText(data.textName)
        
    }
    
    function clearText(){
        setValue('textName', '')
        setTextName('')
        handleChangeText('')
    }

    const list = (anchor) => {
        const checkUser = user && user.isLoggedIn
        return(
            <div 
                className='overflow-hidden'
                role="presentation" 
                //onClick={toggleDrawer(anchor, false)}
                onKeyDown={toggleDrawer(anchor, false)}
            >
                <div className='flex flex-row'>
                    <div className='bg-white w-64 px-4 pt-14 h-screen overflow-y-scroll'>
                        <div className='flex flex-col space-y-1'>
                        {cats.filter((e)=> e.attributes.parent.data === null).map((cat, n)=> 
                            <React.Fragment key={n}>
                                <GetEl cat={cat} lvl={0} slug={`/${getSlug(cat)}`}  />
                            </React.Fragment>
                        )}
                        </div>
                    </div>
                    {tempCats.map((cat, n)=> {
                        const catsTemp = checkUser ? cat.attributes?.child?.data : cat.attributes?.child?.data?.filter((e) => e.attributes.notShow !== true)
                        slug += `${getSlug(cat)}`
                        if(!catsTemp || catsTemp.length === 0){
                            return
                        }
                        return(
                            <React.Fragment key={n}>
                                <div className='bg-white w-64 px-4 pt-14 h-screen overflow-y-scroll'>
                                    <div className='flex flex-col space-y-1 '>
                                        <div className='font-bold px-4 py-2.5'>{cat.attributes.name}</div>
                                        <div onMouseEnter={()=>setCats(cat, n)} >
                                            <NextLink
                                                href={`${slug}`} 
                                                className='text-black'
                                                onClick={()=>close(cat, n)}
                                            > 
                                                <ThemedDrawerLink
                                                    className={'py-2.5 px-4 cursor-pointer'}
                                                    style={{color: (tempCats.length - n) === 1 && themeColor}}
                                                >
                                                    <span>Все товары</span>
                                                </ThemedDrawerLink>
                                            </NextLink>
                                        </div>
                                    {catsTemp.map((catT, ni)=> {
                                        return(
                                            <React.Fragment key={(10*(n+1))+ni}>
                                                <GetEl 
                                                    cat={catT} 
                                                    lvl={n+1} 
                                                    slug={`${slug}/${getSlug(catT)}`} 
                                                />
                                            </React.Fragment>
                                        )
                                    })}
                                    </div>
                                </div>
                            </React.Fragment>
                        )
                    })}
                </div>
            </div>
        )
    }

    return(
        <div className="flex flex-col laptop:flex-row space-y-4 laptop:space-y-0 justify-between">
            <form className='w-full laptop:w-3/5' noValidate onSubmit={handleSubmit(onSubmit)}>
                <div className="flex flex-row items-center">
                    <StyledButton
                        onClick={toggleDrawer('left', true)}
                        className={'h-10 px-10'}
                        colorScheme={colorScheme}
                        text={
                            <div className={'flex flex-row items-center justify-center'}>
                                <IconFlaticon className={'pr-3 mt-1 text-lg'} name='rr-apps' />
                                Каталог
                            </div>}
                    />
                    <ThemedOutline className={"flex flex-row w-full ml-5 items-center"}>
                        <InputBase
                            {...register("textName")}
                            className={'h-9'}
                            defaultValue={textName}
                            sx={{ px: 1.5, flex: 1  }}
                            placeholder=""
                            inputProps={{ 'aria-label': 'Поиск товара' }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        className={'bg-transparent hover:bg-transparent'}
                                        onClick={()=> clearText()}
                                        edge="end"
                                        size="small"
                                        title='очистить'
                                    >
                                        <IconFlaticon className='h-4.5 text-gray-4 hover:text-black-1' name='br-cross-small' />
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </ThemedOutline>
                    <StyledIconButton
                        title='найти'
                        type="submit"
                        variant="contained"
                        className={'h-10 w-10 ml-3 normal-case rounded-12'}
                        icon={'br-search'}
                        iconClass={'mt-1 text-xl'}
                        colorScheme={colorScheme}
                    />
                </div>
            </form>
            <div className="flex flex-row space-x-4">
                <RatingFilter
                    sortPrice={sortPrice}
                    handleChangeSortPrice={handleChangeSortPrice}
                    urlPrice={urlPrice}
                    colorScheme={colorScheme}
                />
                <PriceFilter
                    handleChangeFilterPrice={handleChangeFilterPrice}
                    filterPriceStart={filterPriceStart} 
                    setFilterPriceStart={setFilterPriceStart}
                    filterPriceEnd={filterPriceEnd}
                    setFilterPriceEnd={setFilterPriceEnd}
                    colorScheme={colorScheme}
                />
                {/* <div className='cursor-pointer row-center space-x-2 bg-gray3 hover:bg-gray-100 rounded-12 px-6 py-2.5 leading-snug'>
                    <span>Фильтр</span>
                </div> */}
            </div>
            <Drawer
                anchor={'left'}
                open={state['left']}
                onClose={toggleDrawer('left', false)}
            >
                {list('left')}
            </Drawer>
        </div>
    )
}