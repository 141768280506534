import * as React from 'react';
import clsx from 'clsx';
import {getPrice, getCount, getPriceDiscount} from './libPoduct'
import {getThemeColors} from "@/components/showcase/_helpers";

export default function PriceLabel({ product, one, goodId, colorScheme, singleRow }){
    const themeColor = getThemeColors(colorScheme ? colorScheme : 0);

    if(getPrice(product, goodId) === 0){
        return(
            <></>
        )
    }

    if((getPrice(product, goodId) === 0) && (getCount(product) === 0)){
        return(
            <div className='text-gray-400 text-sm leading-none pt-1'>Нет в наличии</div>
        )
    }

    return(
        <div className={clsx(one ? "items-center text-xl laptop:text-3xl font-semibold" : "text-sm tablet:text-base items-start tablet:items-end justify-normal", "flex flex-row text-black")}>
            <div className={'flex flex-row justify-normal'}>
                <div className={'flex flex-row space-x-1 truncate'}>
                    {one ? (
                        <>
                            <span itemProp="price">{getPrice(product, goodId).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}</span> 
                            <span className={clsx(one && "font-semibold")}>₽</span>
                        </>
                    ):(
                        <>
                            <span>{getPrice(product, goodId).toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}</span>
                            <span className={clsx(one && "font-semibold")}>₽</span>
                        </>
                    )}
                    
                </div>
            </div>
            {getPriceDiscount(product) && (
                <div className={'flex flex-row items-center'}>
                    <div className={clsx(one ?  singleRow ? "ml-3.5 mr-3.5 mt-0.5 tablet:mt-0 text-base laptop:text-lg tablet:font-semibold" : "mt-0.5 ml-4 mr-4 text-sm tablet:text-base" : "ml-4 mr-3 text-sm tablet:text-base", "text-gray-14 line-through decoration-1 decoration-gray-14 truncate")}>
                        {product.attributes.price.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")} ₽
                    </div>
                    {one && (
                        <div className={clsx(one ? "flex" : singleRow ? "hidden laptop:flex" : "hidden tablet:flex", "flex-row px-3.5 py-2 bg-pink-3 text-white font-normal rounded-lg opacity-[.85]")}>
                            <img src='/assets/showcase/images/flame.svg' alt='FIRE'/>
                            <span className='ml-1 text-xs tablet:text-sm'>Дешевле на {getPriceDiscount(product)}%</span>
                        </div>
                    )}
                </div>
            )}
        </div>
    )
}
