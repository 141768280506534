import * as React from 'react';
import { useRouter } from 'next/router'
import NextLink from 'next/link';
import Script from 'next/script'
import axios from 'axios'
import clsx from 'clsx';
import { withIronSessionSsr } from "iron-session/next";
import Head from 'next/head'
import useMediaQuery from '@mui/material/useMediaQuery';
import PaginationItem from '@mui/material/PaginationItem';
import ProductRow from '@/components/ecommy/shop/product/ProductRow';
import ProductPage from "@/components/ecommy/shop/product/ProductPage";
import CatsNestedList from '@/components/ecommy/shop/cats/CatsNestedList';
import BreadcrumbsShop from '@/components/ecommy/shop/body/BreadcrumbsShop';
import FilterHeader from '@/components/ecommy/shop/body/FilterHeader';
import Header from "@/components/showcase/Header";
import {
    ButtonPink,
    ButtonTransparent,
} from "@/components/showcase/_controls";
import { axiosTemplate, getShop, getUserSSR } from '../../lib/fetchJson';
import isEmpty from 'lodash/isEmpty';
import Footer from "@/components/showcase/Footer";
import BannerPanel from '@/components/ecommy/shop/banner/BannerPanel';
import {styled} from "@mui/material/styles";
import Pagination from "@mui/material/Pagination";
import {getThemeColors} from "@/components/showcase/_helpers";
import AddProduct from '@/components/ecommy/shop/product/NewProduct';
import BlockedLayout from '@/components/BlockedLayout';
import LandingPage from "@/components/showcase/Landing";
import { getFilterParam, getUrlFilter } from '../../lib/helperJson';
import { sessionOptions } from '../../lib/session';


function Shop({ device, userSSR, notShowId, shop, products, meta, cats, activeCat, product, priceSort, countSort, priceStart, priceEnd, prodText, productsCats }) {
    const {
        push,
        asPath,        // the value: "/question/how-do-you-get-the-current-url-in-nextjs/"
        pathname,   // the value: "/question/[slug]"
    } = useRouter();

    const user = userSSR;
    const [sortPrice, setSortPrice] = React.useState(priceSort ? priceSort : '');
    const [sortCount, setSortCount] = React.useState(countSort ? countSort : '');
    
    const [filterPriceStart, setFilterPriceStart] = React.useState(priceStart ? priceStart : '');
    const [filterPriceEnd, setFilterPriceEnd] = React.useState(priceEnd ? priceEnd : '');
    const [textName, setTextName] = React.useState(prodText ? prodText : '');
    const checkFilter = !!priceSort || !!countSort || !!priceStart || !!priceEnd || !!activeCat
    const checkText = !!prodText
    const mediaMax = useMediaQuery('(max-width:640px)')
    const [screenMobile, setScreenMobile] = React.useState(device === 'mobile');

    React.useEffect(()=>{
        //console.log(mediaMax, screenMobile)
        setScreenMobile(mediaMax)
    }, [mediaMax])

    const urlPrice = getUrlFilter(asPath, 'price')
    const urlCount = getUrlFilter(asPath, 'count')
    const urlPage = getUrlFilter(asPath, 'page')
    const urlText = getUrlFilter(asPath, 'text')
    const urlPriceFilter = getUrlFilter(asPath, 'ps', 'pe')

    function handleChangeFilterPrice(start, end){
        let url = ''
        if(!isEmpty(start)){
            url += `/ps/${start}`
        }
        if(!isEmpty(end)){
            url += `/pe/${end}`
        }
        const path = `${urlPriceFilter.join("/")}${url}`.replace('//', '/')
        push(path, path, {scroll: false})
    }

    function handleChangeText(text){
        setFilterPriceStart('')
        setFilterPriceEnd('')
        setSortPrice('')
        if(isEmpty(text)){
            push(`/`, `/`, {scroll: false})
        }else{
            push(`/text/${text}`, `/text/${text}`, {scroll: false})
        }
    }

    function handleChangeSortPrice(event){
        setSortPrice(event.target.value)
        let path = `${urlPrice.join("/")}`.replace('//', '/')
        switch (event.target.value) {
            case 'disc':
                path = `${urlPrice.join("/")}/price/disc`.replace('//', '/')
                push(path, path, {scroll: false})
                break;
            case 'asc':
                path = `${urlPrice.join("/")}/price/asc`.replace('//', '/')
                push(path, path, {scroll: false})
                break;
            case 'desc':
                path = `${urlPrice.join("/")}/price/desc`.replace('//', '/')
                push(path, path, {scroll: false})
                break;
            default:
                push(path, path, {scroll: false})
                break;
        }
    }

    function handleChangeSortCount(event){
        setSortCount(event.target.value)
    }
    const structuredDataLd = [{
            "@context": "https://schema.org",
            "@type": "BreadcrumbList",
            "itemListElement": [
                {
                    "@type": "ListItem",
                    "position": 1,
                    "item":{
                        "@id": "https://cp.ecommy.ru/showcase/start",
                        "name": "Создать магазин"
                    }
                },
                {
                    "@type": "ListItem",
                    "position": 2,
                    "item": {
                        "@id": "https://topshop.ecommy.ru",
                        "name": "Посмотреть демо"
                    }
                }
            ]
        },
        {
            "@context": "https://schema.org/",
            "@type": "Service",
            "name": "Интернет-магазин в аренду",
            "url": "https://ecommy.ru",
            "description": "Ваш собственный интернет-магазин всего за 1190 руб./месяц. От регистрации до начала торговли — один день. Уникальный адрес магазина на выбор. Все нужные инструменты, передовые технологии, техническая поддержка."
        },
        {
            "@context": "https://schema.org",
            "@type": "FAQPage",
            "mainEntity": [{
              "@type": "Question",
              "name": "Почему выбирают ecommy?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Это лучшее решение, если вы хотите начать интернет-продажи уже сегодня. Вам не нужно вникать в технические детали создания сайта, переплачивать за личный интернет-магазин, запасаться терпением на разработку и доработки — вы сразу получаете все необходимые инструменты для продаж. Мы используем только современные инструменты программирования, а значит магазин работает быстро, хорошо считывается в поисковых системах, и процент отказов покупателей снижается."
              }
            }, {
              "@type": "Question",
              "name": "Как быстро запустится магазин?",
              "acceptedAnswer": {
                "@type": "Answer",
                "text": "Мы настроим адрес (домен) вашего магазина в течение часа после регистрации, и вам придет уведомление, что магазин готов к работе. Вы сможете начать продавать даже без дополнительных настроек — в форме регистрации мы собираем всю необходимую информацию для запуска."
              }
            }, {
                "@type": "Question",
                "name": "Что входит в аренду?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Помимо самого интернет-магазина, в стоимость входит хостинг, обслуживание, хранение данных, техподдержка и добавление нового функционала. Нет скрытых комиссий и навязанных дополнительных услуг."
                }
            }, {
                "@type": "Question",
                "name": "Сколько длится аренда?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Вы можете оплатить аренду на срок 30, 90, 180 или 360 дней. Чем больше срок аренды, тем выгоднее скидка."
                }
            }, {
                "@type": "Question",
                "name": "Берется ли комиссия за продажи?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Нет, ecommy не взимает комиссию с продажи ваших товаров."
                }
            }, {
                "@type": "Question",
                "name": "Можно ли запускать рекламу на этот магазин?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Да, вы можете настраивать и запускать таргетированную рекламу на адрес своего магазина, отдельные товары или группы товаров. Запускать рекламу можно в поисковых системах, а также на любой площадке, где принимаются ссылки на внешние сайты (социальные сети, мессенджеры)."
                }
            }, {
                "@type": "Question",
                "name": "Что если мне нужен дополнительный функционал?",
                "acceptedAnswer": {
                  "@type": "Answer",
                  "text": "Свяжитесь с нами и расскажите подробно, какие интеграции или инструменты вы хотели бы видеть у себя в интернет-магазине. Мы открыты к доработкам, а значит рассмотрим заявку и сформируем индивидуальное предложение."
                }
            }]
          }
    ]
    //Заглушка если магазин в разработке () ()
    if(cats.length === 0 && products.length === 0){
        if(!shop && user && !user.isLoggedIn){
            return (
                <>
                    <Head>
                        <title>Интернет-магазин в аренду | Создание сайта для онлайн-продаж</title>
                        <meta name="description" content="Ваш собственный интернет-магазин всего за 1190 руб./месяц. От регистрации до начала торговли — один день. Уникальный адрес магазина на выбор. Все нужные инструменты, передовые технологии, техническая поддержка." key="description" />
                        <meta property="og:title" content="Интернет-магазин в аренду | Создание сайта для онлайн-продаж" key="og:title" />
                        <meta
                            property="og:description"
                            content="Ваш собственный интернет-магазин всего за 1190 руб./месяц. От регистрации до начала торговли — один день. Уникальный адрес магазина на выбор. Все нужные инструменты, передовые технологии, техническая поддержка."
                            key="og:description"
                        />
                        <meta property="vk:image" content="/logo-vk.png" />
                        <meta property="og:image" content="/logo-og.png" />
                        <meta property="og:type" content="website" />
                        <meta property="og:url" content="ecommy.ru" />
                        <meta name="yandex-verification" content="177a19ede7777652" />
                        <meta name="mailru-verification" content="feb26a34d844f691" />
                    </Head>
                    <script
                        type="application/ld+json"
                        dangerouslySetInnerHTML={{ __html: JSON.stringify(structuredDataLd) }}
                    />
                    <LandingPage device={device}/>
                </>
            )
        }else if(!shop && user && user.isLoggedIn){
            return (
                <>
                    <div className='w-full flex flex-row items-center justify-center mt-36'>
                        Магазин в разработке
                    </div>
                </>
            )
        }else if(shop && user && user.isLoggedIn){
            return(
                <>
                    <Header showExit={user && user.isLoggedIn} type={'full'} userInfo={user} showDemo={false} showCart={false} showSettings={true} showFilters={false} showBalance={false} />
                    <div className='flex flex-col w-full tablet:w-96 laptop:w-130 px-3 tablet:px-0 mt-8 pb-12 items-center'>
                        <img src='/assets/showcase/images/shop-empty-ph.svg' className='w-auto max-w-xs tablet:max-w-122' alt='EMPTY'/>
                        <div className='mt-15 mb-8 text-center'>Добавьте товары в свой интернет-магазин</div>
                        <ButtonPink onClick={() => push('/showcase/product')} className='max-w-76 w-full py-3 bg-gradient-to-r from-pink-1 to-purple-1 hover:brightness-90'>
                            Добавить первый товар
                        </ButtonPink>
                    </div>
                </>
            )
        }
    }

    if(shop && shop.is_shop_blocked && user && !user.isLoggedIn){
        return(
            <BlockedLayout />
        )
    }

    const StyledPagination = styled(Pagination)(({ theme }) => ({
        '& .MuiPaginationItem-root': {
            width: '2rem',
            height: '2rem',
            fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica",
            backgroundColor: '#F2F2F2',
            '&:hover': {
                color: 'rgb(255, 255, 255)',
                backgroundColor: getThemeColors(shop.shopColor)+'!important'
            },
        },
        '& .Mui-selected': {
            color: 'rgb(255, 255, 255)',
            backgroundColor: getThemeColors(shop.shopColor)+'!important',
            '&:hover': {
                backgroundColor: getThemeColors(shop.shopColor)+'!important'
            },
            fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica",
        },
    }));

    function getTitle(){
        let title = shop.shopDescription 
        if(activeCat){
            title = activeCat.attributes.name
        }
        if(product){
            title = product.attributes.name
        }
        return title
    }

    function getDesc(){
        let desc = shop.shopDescriptionFull
        if(product){
            desc = product.attributes.description
        }
        return desc
    }

    return (
        <>
            <Head>
                <title>{getTitle()}</title>
                <meta name="description" content={getDesc()} key="description" />
                <meta property="og:title" content={getTitle()} key="og:title" />
                <meta
                    property="og:description"
                    content={getDesc()}
                    key="og:description"
                />
                {shop.shopLogo && (
                    <meta property="og:image" content={shop.shopLogo ? process.env.NEXT_PUBLIC_UPLOAD_STRAPI_URL+shop.shopLogo.url : '/logo.png'} />
                )}
                <meta property="og:type" content="website" />
                <meta property="og:url" content= {`https://ecommy.ru${asPath}`} />
            </Head>
            <Header
                type={'full'}
                userInfo={shop}
                showBack={screenMobile && product}
                showExit={user && user.isLoggedIn}
                showCart={!user || !user.isLoggedIn}
                showSettings={user && user.isLoggedIn}
                showFilters={screenMobile && !product}
                // showNotification={false}
                filterParams={{
                    categoriesList: cats,
                    categoriesActive: activeCat,
                    priceSort: priceSort,
                    priceStart: priceStart,
                    priceEnd: priceEnd,
                    searchValue: textName,
                    setSearchValue: setTextName
                }}
                checkFilter={checkFilter}
                checkText={checkText}
                showDemo={false}
                showBalance={false}
            />
            <div className={clsx(product ? '-mt-8 tablet:mt-0' : 'mt-6 tablet:mt-0', 'flex flex-col w-full max-w-750 min-h-screen')}>
                <BannerPanel user={user} shop={shop} product={product} activeCat={activeCat} colorScheme={shop.shopColor} />
                {!screenMobile  && activeCat && (
                    <div className='w-full space-y-2 px-3 laptop:px-0'>
                        <BreadcrumbsShop cat={activeCat} product={product} colorScheme={shop.shopColor} />
                    </div>
                )}
                {!product && !screenMobile && (
                    <div className={'px-3 laptop:px-0'}>
                        <div className='pt-8'>
                            <FilterHeader
                                user={user}
                                cats={cats}
                                activeCat={activeCat}
                                sortPrice={sortPrice}
                                handleChangeSortPrice={handleChangeSortPrice}
                                urlPrice={urlPrice}
                                handleChangeFilterPrice={handleChangeFilterPrice}
                                filterPriceStart={filterPriceStart}
                                setFilterPriceStart={setFilterPriceStart}
                                filterPriceEnd={filterPriceEnd}
                                setFilterPriceEnd={setFilterPriceEnd}
                                handleChangeText={handleChangeText}
                                textName={textName}
                                setTextName={setTextName}
                                colorScheme={shop.shopColor}
                            />
                        </div>
                        <div className='pt-8'>
                            <CatsNestedList user={user} notShowId={notShowId} cats={cats} activeCat={activeCat} top meta={meta} colorScheme={shop.shopColor} />
                        </div>
                    </div>
                )}
                <div className='flex flex-row space-x-10 mt-0 laptop:mt-1.5'>
                    <div className='flex flex-col space-y-3 w-full'>
                        {product ? (
                            <ProductPage user={user} cats={cats} productsCats={productsCats} product={product} colorScheme={shop.shopColor}/>
                        ) : (
                            <div className="w-full grid grid-cols-2 tablet:grid-cols-3 laptop:grid-cols-4 gap-x-4 gap-y-2 laptop:gap-y-3 px-3 laptop:px-0 py-3 laptop:py-4">
                                {products.map((el, n)=>
                                    <React.Fragment key={n}>
                                        <ProductRow user={user} product={el} n={n} cats={cats} colorScheme={shop.shopColor} />
                                    </React.Fragment>
                                )}
                            </div>
                        )}
                        {(!product && meta && meta.pagination.pageCount > 1) && (
                            <div className='pt-4 pb-6'>
                                <StyledPagination
                                    count={meta.pagination.pageCount} 
                                    page={meta.pagination.page} 
                                    //onChange={handleChangePage} 
                                    renderItem={(item) => (
                                        <PaginationItem
                                          component={NextLink}
                                          href={`${urlPage.join("/")}${urlPage.join("/") !== '/' ? '/' : ''}page/${item.page}`}
                                          {...item}
                                        />
                                    )}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <AddProduct user={user} />
            <Footer className={product && 'pb-10 tablet:pb-0'} user={user} userInfo={shop} productPage={!!product}/>
        </>
    )
}
  
export default Shop

export const getServerSideProps = withIronSessionSsr(
    async function getServerSideProps(ctx) {
        const { req, query } = ctx
        const shop = await getShop(req, req.session)

        // Выборка ИД категорий от активной по иерархии
        let catsFilter = [] 
        function getIdCats(c){
            const child = c.attributes?.child?.data
            if(child && child.length > 0){
                child.forEach((e, n) => {
                    catsFilter.push(e.id)
                    getIdCats(e)
                })
            }
        }
        
        // параметры фильтра
        let product = null
        let prodId = getFilterParam(query, 'product')
        let prodText = getFilterParam(query, 'text')
        let pageId = getFilterParam(query, 'page', 1)
        let priceId = getFilterParam(query, 'price')
        let priceStart = getFilterParam(query, 'ps')
        let priceEnd = getFilterParam(query, 'pe')
        let countId = getFilterParam(query, 'count')
        let notShowId = getFilterParam(query, 'not-show')
        let activeCat = null
        let slugActiveCat = null

        // slug активной категории
        if(query.slug && query.slug.length > 0){
            slugActiveCat = query.slug[query.slug.length-1]
        }
        
        const notShow = shop && req.session.user && (req.session.user?.id === shop?.id) ? false : true 
        //выборка категорий
        let responseCats = { data: null, error: null }
        if(shop){
            const urlNotShow = `${notShow ? `&filters[notShow][$ne]=true` : ''}`
            const urlCat = `/categories?sort[0]=id:desc&filters[userId][$eq]=${shop.id}${urlNotShow}&populate[0]=child&populate[1]=parent&populate[2]=child.child&populate[3]=parent.parent&pagination[pageSize]=25`
            const responseCatTemp = await axiosTemplate(urlCat, "GET", null, false, null)
            responseCats.data = [...responseCatTemp.data.data]
            responseCats.error = responseCatTemp.error
            if(responseCatTemp.data.meta && responseCatTemp.data.meta.pagination.pageCount > 1){
                for(let i = 2; i <= responseCatTemp.data.meta.pagination.pageCount; i++ ){
                    const responseCatTemp2 = await axiosTemplate(`${urlCat}&pagination[page]=${i}`, "GET", null, false, null)
                    responseCats.data = [...responseCats.data, ...responseCatTemp2.data.data]
                }
            }
        }

        if((responseCats.data && responseCats.data.length > 0) && slugActiveCat){
            const activeCatT = responseCats.data.find((el) => el.attributes.slug === slugActiveCat)
            //console.log(slugActiveCat, activeCatT)
            if(activeCatT){
                activeCat = activeCatT
                catsFilter.push(activeCat.id)
                getIdCats(activeCat)
            }else{
                return {
                    notFound: true,
                }
            }
        }

        //Выборка товаров
        const productRel = `populate[0]=offers&populate[1]=images&populate[2]=cats&populate[3]=offers.type&populate[4]=offers.type&populate[5]=stores&populate[6]=stores.store`
        let responseProducts = { meta: null, data: null, error: null }
        if(shop){
            try {
                //console.log('--- cts ---', catsFilter)
                const urlProds = catsFilter.length > 0 ? `?${productRel}&${catsFilter.map((c)=> {
                    return `filters[cats][id][$in]=${c}`
                }).join('&')}` : `?${productRel}`
                let populateGoog = `populate[7]=goods&populate[8]=goods.setGood&populate[9]=goods.setGood.item`
                let populateGoogItem = `populate[10]=goods.setGood.item.offers&populate[11]=goods.setGood.item.images&populate[12]=goods.setGood.item.offers.type&populate[13]=goods.setGood.item.offers.type&populate[14]=goods.setGood.item.stores&populate[15]=goods.setGood.item.stores.store`
                let url = prodId ? `?${productRel}&${populateGoog}&${populateGoogItem}&filters[slug][$eq]=${prodId}` :  urlProds
                url += `&pagination[pageSize]=30&pagination[page]=${pageId}`//&filters[count][$gt]=0
                if(countId){
                    url += `&sort[0]=count:${countId}`
                }
                if(!notShow){
                    if(notShowId === 'cat'){
                        url += `&filters[cats][id][$null]=true`
                    }else if(notShowId === 'hide'){
                        url += `&filters[notShow][$ne]=false`
                    }
                }
                url += `&filters[userId][$eq]=${shop.id}${notShow ? `&filters[notShow][$ne]=true` : ''}`
                url += prodId ? '' : `${notShow ? `&filters[cats][notShow][$ne]=true` : ''}`
                if(priceStart){
                    url += `&filters[$or][0][offers][price][$gt]=${priceStart}&filters[$or][1][price][$gt]=${priceStart}`
                }else{
                    url += `&filters[$or][0][offers][price][$gt]=0&filters[$or][1][price][$gt]=0`
                }
                if(priceEnd){
                    url += `&filters[$or][0][offers][price][$lt]=${priceEnd}&filters[$or][1][price][$lt]=${priceEnd}`
                }
                if(prodText){
                    url += `&filters[name][$containsi]=${prodText}`  
                }
                if(priceId && (priceId !== 'disc')){
                    url += `&sort[1]=price:${priceId}` 
                }else if(priceId === 'disc'){
                    url += `&sort[1]=productDiscount:desc`
                }else{
                    url += `&sort[2]=createdAt:desc`
                }
                
                //console.log("=== url ===",  `${process.env.NEXT_PUBLIC_STRAPI_URL}/products${url}`)
                const res = await axios.get(`${process.env.NEXT_PUBLIC_STRAPI_URL}/products${url}`);
                responseProducts.data = res.data.data;
                responseProducts.meta = res.data.meta;
            } catch (error) {
                if (error.response) {
                    responseProducts.error = error.response.body
                } else if (error.request) {
                    responseProducts.error = error.request
                } else if(error.message){
                    responseProducts.error = error.message
                }else {
                    responseProducts.error = error
                }
            }
        }

        // если одиночная страница товара
        let responseProductsCats = { meta: null, data: null, error: null }
        if(prodId && shop){
            if(responseProducts.data && responseProducts.data.length > 0){
                product = responseProducts.data[0]
                if(product.attributes?.cats?.data && product.attributes?.cats?.data?.length > 0){
                    try {
                        let url = `?filters[userId][$eq]=${shop.id}&${productRel}&filters[id][$ne]=${product.id}&filters[cats][id][$in]=${product.attributes.cats.data[0].id}`
                        const res = await axios.get(`${process.env.NEXT_PUBLIC_STRAPI_URL}/products${url}`);
                        responseProductsCats.data = res.data.data;
                        responseProductsCats.meta = res.data.meta;
                    } catch (error) {
                        if (error.response) {
                            responseProductsCats.error = error.response.body
                        } else if (error.request) {
                            responseProductsCats.error = error.request
                        } else if(error.message){
                            responseProductsCats.error = error.message
                        }else {
                            responseProductsCats.error = error
                        }
                    }
                }
            }else{
                return {
                    notFound: true,
                }
            }
        }

        // если ошибка получения товара из апи, то 404
        if(responseProducts.error){
            return {
                notFound: true,
            }
        }

        let userSSR = { isLoggedIn: false, login: "", avatarUrl: "" }
        if(req.session.user){
            userSSR = await getUserSSR(req)
        }
        
        return {
            props: {
                userSSR: userSSR,
                notShowId,
                countSort: countId,
                priceSort: priceId,
                priceStart, 
                priceEnd,
                prodText,
                products: responseProducts.data || [],
                productsCats: responseProductsCats.data || [],
                meta: responseProducts.meta,
                cats: responseCats.data || [],
                activeCat,
                product
            },
        }
    },
    sessionOptions
)
