import * as React from 'react';
import { useRouter } from 'next/router'
import clsx from 'clsx';
import IconFlaticon from "../../../IconFlaticon"
import {styled} from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import AddCartProduct from './AddCartProduct';
import PriceLabel from './PriceLabel';
import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";
import {SwiperSlide} from "swiper/react";
import {Navigation} from "swiper";
import 'swiper/swiper.min.css';
import ProductRow from '@/components/ecommy/shop/product/ProductRow';
import {SwiperDTLanding} from '@/components/ecommy/shop/MaterialShop';
import {getCountType, getImage, getPrice, getPriceType} from './libPoduct';
import useMediaQuery from "@mui/material/useMediaQuery";
import ProductPanel from './ProductPanel';
import {getThemeColors} from "@/components/showcase/_helpers";

const DivGal = styled('div')(({ theme, themeColor, screenPhone }) => ({
    '& .productImageFeaturedStar': {
        position: 'absolute',
        top: 0,
        right: 0,
        color: 'rgb(243, 95, 55)',
        opacity: 0,
    },
    '& .productImageDelete': {
        position: 'absolute',
        top: 5,
        right: 5,
        color: '#FF5353',
        backgroundColor: '#FF5353',
        borderRadius: '100%',
        opacity: 0.7,
        '&:hover': {
            backgroundColor: '#FF5353',
            opacity: 1.0,
        },
    },
    
    '& .productImageUpload': {
        transitionProperty: 'box-shadow',
        transitionDuration: theme.transitions.duration.short,
        transitionTimingFunction: theme.transitions.easing.easeInOut,
    },
    
    '& .productImageItem': {
        transitionProperty: 'box-shadow',
        transitionDuration: theme.transitions.duration.short,
        transitionTimingFunction: theme.transitions.easing.easeInOut,
        '&:hover': {
          '& .productImageFeaturedStar': {
            opacity: 0.8,
          },
        },
        '&.featured': {
          pointerEvents: 'none',
          boxShadow: theme.shadows[3],
          '& .productImageFeaturedStar': {
            opacity: 1,
          },
          '&:hover .productImageFeaturedStar': {
            opacity: 1,
          },
        },
    },
    '& .image-gallery-right-nav > .image-gallery-svg': {
        height: '45px !important',
        width: '45px !important'
    },
    '& .image-gallery-left-nav > .image-gallery-svg': {
        height: '45px !important',
        width: '45px !important'
    },
    '& .image-gallery-fullscreen-button > .image-gallery-svg': {
        height: '30px !important',
        width: '30px !important'
    },
    '& .image-gallery-image':{
        height: '34.5rem !important',
        [theme.breakpoints.up('sm')]: {
            height: '41rem !important',
        },
    },
    '& .image-gallery-slides': {
        borderRadius: screenPhone ? '0px' : '12px',
    },
    '& .image-gallery-slide-wrapper': {
        [theme.breakpoints.up('sm')]: {
            border: '1px solid #F1F1F1',
            borderRadius: '12px'
        },
        [theme.breakpoints.up('lg')]: {
            marginBottom: '1rem'
        }
    },
    '& .fullscreen': {
        '& .image-gallery-image':{
            border: '0px',
            borderRadius: '0px',
            height: '100vh !important',
            maxHeight: '100vh !important',
            objectFit: 'none'
        },
        '& .image-gallery-slides':{
            border: '0px',
            borderRadius: '0px',
        },
        '& .image-gallery-thumbnails': {
            opacity: '0.1',
            position: 'fixed !important',
            bottom: '0',
            left: '0',
            width: '100%',
            paddingLeft: '0.5rem !important',
            paddingBottom: '0.75rem !important',
            backgroundColor: 'transparent',
            transitionDuration: '500ms',
            [theme.breakpoints.down('md')]: {
                paddingLeft: '0rem !important',

            },
            [theme.breakpoints.down('sm')]: {
                paddingLeft: '0rem !important',

            },
            '&:hover' : {
                opacity: '1',
                backgroundColor: 'rgba(0,0,0,.5)',
            }
        },
        '& .image-gallery-slide-wrapper':{
            border: '0px',
            borderRadius: '0px',
        },
        '& .image-gallery-fullscreen-button':{
            // position: 'fixed',
            // top: 0,
            // right: '0.75rem',
            // height: '60px',
            // padding: 0
        },
    },
    '& .image-gallery-fullscreen-button':{
        '&:hover': {
            color: themeColor,
        },
        // [theme.breakpoints.down('md')]: {
        //     display: 'none'
        // }
    },
    '& .image-gallery-thumbnails-container': {
        // maxWidth: '115rem',
        cursor: 'default !important',
        textAlign: 'left !important',
        paddingLeft: '0.2rem !important',
        // paddingTop: '0.1rem !important',
        [theme.breakpoints.down('md')]: {
            paddingTop: '0.75rem !important',
            paddingLeft: '0rem !important',
            paddingRight: '1.25rem !important'
        },
        [theme.breakpoints.down('sm')]: {
            paddingTop: '0.75rem !important',
            paddingLeft: '0.75rem !important',
            paddingRight: '0.75rem !important'
        }
    },
    '& .image-gallery-right-nav':{
        '&:hover': {
            color: themeColor,
        }
    },
    '& .image-gallery-left-nav':{
        '&:hover': {
            color: themeColor,
        }
    },
    '& .image-gallery-thumbnail-image': {
        borderRadius: '0.75rem',
        padding:'0.125rem'
    },
    '& .image-gallery-thumbnail': {
        overflow: 'hidden',
        width: '70px',
        cursor: 'pointer',
        border: '2px solid transparent !important',
        borderRadius: '0.75rem',
        '&:hover': {
            border: '2px solid',
            borderColor: themeColor+'!important'
        }
    },
    '&:focus':{
        border: '2px solid',
        borderColor: themeColor+'!important'
    },
    '& .active': {
        border: '2px solid',
        borderColor: themeColor+'!important'
    },
}));

export default function ProductPage({ user, product, productsCats, cats, colorScheme}){
    const { replace, push, asPath, pathname } = useRouter();
    const swiperRef = React.useRef(null);
    const [goodId, setGoodId] = React.useState(0);
    const showSwiperControls = productsCats && productsCats.length > 4;
    const [showSwiperNext, setShowSwiperNext] = React.useState(false);
    const [showSwiperPrev, setShowSwiperPrev] = React.useState(false);
    const screenPhone = useMediaQuery('(max-width:470px)');
    const screenMobile = useMediaQuery('(max-width:640px)');
    const themeColor = getThemeColors(colorScheme ? colorScheme : 0);
    const imageRef = React.useRef(null);

    function getImagesCount(product){
        return !!(product.attributes?.images?.data && product.attributes?.images?.data.length > 0);
    }

    function getStartIndex(product){
        let startIndex = 0
        if((product.attributes?.images?.data && product.attributes?.images?.data.length > 0) && (product.attributes.default_image && product.attributes.default_image > 0)){
            startIndex = product.attributes?.images?.data.findIndex((e) => e.id === product.attributes.default_image )
        }
        return startIndex
    }

    function getImages(product){
        if(product.attributes?.images?.data && product.attributes?.images?.data.length > 0){
            return product.attributes?.images?.data.map((img)=>{
                return {
                    fullscreen: `${process.env.NEXT_PUBLIC_UPLOAD_STRAPI_URL}${img.attributes.url}`,
                    original: `${process.env.NEXT_PUBLIC_UPLOAD_STRAPI_URL}${img.attributes.url}?format=webp&resize=445x655&embed&quality=100`,
                    thumbnail: `${process.env.NEXT_PUBLIC_UPLOAD_STRAPI_URL}${img.attributes.formats.thumbnail.url}?format=webp&resize=70x70&embed&quality=100`,
                }
            })
        }else{
            return [{
                original: `/assets/showcase/images/no-image.svg`,
                thumbnail: `/assets/showcase/images/no-image.svg`,
            }]
        }
    }

    function getCartGoods(prod){
        if(prod.attributes.goods.length>0){
            const findG = prod.attributes.goods.find((e)=> e.id === goodId)
            if(findG){
                let good = findG.setGood.map((g2)=>{
                    return g2.item.data
                })
                return good.map((g) => {
                    return {
                        id: g.id,
                        count: g.attributes.count,
                        name: g.attributes.name,
                        slug: g.attributes.slug,
                        price: getPrice(g),
                        image: getImage(g),
                        priceId: getPriceType(g),
                        storeId: getCountType(g),
                        goodId: undefined,
                        qty: 1
                    }
                })
            }
        }
        return null
    }

    return(
        <div itemScope itemType="http://schema.org/Product" className='relative w-full pt-0 tablet:pt-6 laptop:pt-6'>
            <div className='flex flex-col tablet:flex-row justify-between tablet:space-x-4 laptop:space-x-25'>
                <DivGal themeColor={themeColor} screenPhone={screenPhone} className='w-full tablet:max-w-md pl-0 tablet:pl-5 laptop:pl-0'>
                    {getImagesCount(product) ? (
                        <>  
                            <img className='hidden' src={getImages(product)[0].fullscreen} title={product.attributes.name} itemProp="image" />
                            <ImageGallery
                                ref={imageRef}
                                items={getImages(product)}
                                showPlayButton={false}
                                startIndex={getStartIndex(product)}
                            />
                        </>
                    ) : (
                        <img className='w-full max-h-180 border border-none tablet:border-solid border-gray-11 rounded-xl' src='/assets/showcase/images/no-image.svg'  alt=""/>
                    )}
                </DivGal>
                <div className='flex flex-col tablet:max-w-88 tablet-xl:max-w-132 laptop:max-w-166 w-full py-5 tablet:py-0'>
                    {screenMobile && (
                        <div itemProp="offers" itemScope itemType="http://schema.org/Offer" className='w-full mb-3 pt-5 px-3 border-0 border-t border-solid border-white-1'>
                            <PriceLabel product={product} goodId={goodId} one={true} colorScheme={colorScheme} />
                            <span className='hidden' itemProp="priceCurrency">RUB</span>
                        </div>
                    )}
                    <h1 itemProp="name" className='mb-4 px-3 laptop:px-0 font-semibold text-black text-xl laptop:text-3xl leading-7 laptop:leading-10'>
                        {product.attributes.name}
                    </h1>
                    {!screenMobile && (
                        <div itemProp="offers" itemScope itemType="http://schema.org/Offer" className={clsx('flex flex-col mb-8 px-3 laptop:px-0 space-y-4 laptop:space-y-7 justify-between')}>
                            <PriceLabel product={product} goodId={goodId} one={true} colorScheme={colorScheme} />
                            <span className='hidden' itemProp="priceCurrency">RUB</span>
                            {(user && user.isLoggedIn) ? (
                                <ProductPanel user={user} product={product} colorScheme={colorScheme} />
                            ) : (
                                <AddCartProduct slug={asPath} product={product} goodId={goodId} goodsCart={getCartGoods(product)} one={true} colorScheme={colorScheme}/>
                            )}
                        </div>
                    )}
                    <div className='mb-1 tablet:mb-3.5 px-3 laptop:px-0 text-sm tablet:text-base font-semibold'>Описание:</div>
                    <p className='mb-7.5 px-3 laptop:px-0 text-sm tablet:text-base leading-6 whitespace-pre-line break-words'>
                        <span itemProp="description">{product.attributes.description}</span>
                    </p>
                    {product.attributes.productDetails && (
                        <>
                            <div className='mb-1 tablet:mb-3.5 px-3 laptop:px-0 text-sm tablet:text-base font-semibold'>Характеристики:</div>
                            <p className='mb-7.5 px-3 laptop:px-0 text-sm tablet:text-base leading-6 whitespace-pre-line break-words'>{product.attributes.productDetails}</p>
                        </>
                    )}
                </div>
            </div>
            {productsCats && productsCats.length > 0 && (
                <>
                    <div className='h-px w-full mb-5 laptop:my-12 bg-white-6'/>
                    <div className='px-3 laptop:px-0 font-bold text-xl laptop:text-3xl leading-none'>Похожие товары:</div>
                    <div className="pt-8 pb-8 tablet:pb-16 flex flex-row">
                        <IconButton
                            className={clsx("w-10 h-10 -ml-15 mr-4 mt-48 shadow-md rounded-full", showSwiperControls && showSwiperPrev ? 'hidden laptop:block' : 'hidden')}
                            onClick={() => swiperRef.current?.slidePrev()}>
                            <IconFlaticon
                                style={{color:themeColor}}
                                name="rr-arrow-left"
                            />
                        </IconButton>
                        <SwiperDTLanding
                            className="!mx-0"
                            modules={[Navigation]}
                            onBeforeInit={(swiper) => {
                                swiperRef.current = swiper;
                            }}
                            // Default parameters
                            slidesPerView="auto"
                            spaceBetween={0}
                            onSwiper={(swiper) => {
                                setShowSwiperNext(productsCats.length > 4);
                                setShowSwiperPrev(swiper.activeIndex > 0);
                            }}
                            onSlideChange={(swiper)=>{
                                setShowSwiperNext(productsCats.length - swiper.activeIndex > 4);
                                setShowSwiperPrev(swiper.activeIndex > 0);
                            }}
                        >
                            {productsCats && productsCats.map((el, n)=> {
                                return(
                                    <SwiperSlide key={`slide${n}`}>
                                        <React.Fragment key={`prod${n}`}>
                                            <ProductRow user={user} product={el} n={n} cats={cats} singleRow={true} colorScheme={colorScheme}/>
                                        </React.Fragment>
                                    </SwiperSlide>
                                )
                            })}
                        </SwiperDTLanding>
                        <IconButton
                            className={clsx("w-10 h-10 ml-4 -mr-15 mt-48 shadow-md rounded-full", showSwiperControls && showSwiperNext ? 'hidden laptop:block' : 'hidden')}
                            onClick={() => swiperRef.current?.slideNext()}>
                            <IconFlaticon
                                style={{color:themeColor}}
                                name="rr-arrow-right"
                            />
                        </IconButton>
                    </div>
                </>
            )}
            <div className="fixed tablet:hidden z-50 w-full bottom-0 px-3 py-2.5 bg-white border-0 border-t border-solid border-white-1">
                {(user && user.isLoggedIn) ? (
                    <ProductPanel user={user} product={product} colorScheme={colorScheme} />
                ) : (
                    <AddCartProduct slug={asPath} product={product} goodId={goodId} goodsCart={getCartGoods(product)} one={true} colorScheme={colorScheme}/>
                )}
            </div>
        </div>
    )
}
