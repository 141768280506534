import * as React from 'react';
import clsx from 'clsx'
import Image from 'next/image'
import { useState } from "react";
import Link from '../../../../theme/Link';
import AddCartProduct from './AddCartProduct';
import PriceLabel from './PriceLabel';
import StoreLabel from './StoreLabel';
import useUser from "../../../../../lib/useUser";
import {ButtonGray, HoverEffectImage, StyledButton} from "@/components/showcase/_controls";
import NextLink from "next/link";
import IconFlaticon from "@/components/IconFlaticon";
import {getPriceDiscount} from "@/components/ecommy/shop/product/libPoduct";
import {getThemeColors} from "@/components/showcase/_helpers";

const imgSize = {
    width: 286,
    height: 414,
    quality: 100
}

export default function ProductRow({ user, product, n, cats, singleRow, colorScheme, imageSize}){

    let slugParents = [];

    function getSlugParent(c){
        if(c.attributes?.parent?.data){
            const s1 = `${c.attributes.parent.data.attributes.slug}`
            slugParents.push(s1)
            getSlugParent(c.attributes.parent.data)
        }
    }

    const catsT = product.attributes?.cats?.data
    if(catsT && catsT.length > 0){
        const aCat = cats.find((e) => e.id === catsT[0].id)
        if(aCat){
            const s1 = `${aCat.attributes.slug}`
            slugParents.push(s1)
            getSlugParent(aCat)
        }
    }
    slugParents.reverse()

    function getRandomInRange(min,max){
        return  Math.floor(Math.random() * (max - min) + min)
    }

    function getImage(product){
        if(product.attributes?.images?.data && product.attributes?.images?.data.length > 0){
            let startIndex = 0, altIndex = product.attributes?.images?.data.length > 1 ? 1 : 0;
            if(product.attributes.default_image && product.attributes.default_image > 0){
                startIndex = product.attributes?.images?.data.findIndex((e) => e.id === product.attributes.default_image)
                altIndex = startIndex === 0 && product.attributes?.images?.data.length > 1 ? 1 : 0;
            }
            return {
                default: `${process.env.NEXT_PUBLIC_UPLOAD_STRAPI_URL}${product.attributes?.images?.data[startIndex].attributes.url}?format=webp&resize=${imgSize.width}x${imgSize.height}&embed&quality=${imgSize.quality}`,
                alternative: `${process.env.NEXT_PUBLIC_UPLOAD_STRAPI_URL}${product.attributes?.images?.data[altIndex].attributes.url}?format=webp&resize=${imgSize.width}x${imgSize.height}&embed&quality=${imgSize.quality}`
            }
        }
        return {default: `/assets/showcase/images/no-image.svg`}
    }

    function getSlugHref(){
        return `/${slugParents.length > 0 ? `${slugParents.join('/')}/` : ''}product/${product.attributes.slug}`
    }

    const GetLink = ({children}) => {
         return(
            <Link {...(singleRow && { prefetch: false })} className='relative no-underline text-black' href={getSlugHref()}>
                {children}
            </Link>
         )
    }

    const notShowCat = product.attributes.cats.data && product.attributes.cats.data.length > 0 ? product.attributes.cats.data.filter((e)=> e.attributes.notShow === true).length > 0 : false

    return(
        <div className={clsx('flex flex-col', singleRow ? 'space-y-3.5' : 'space-y-2.5 tablet:space-y-2.5 mb-4')}>
            <GetLink>
                {getImage(product).alternative ? (
                    <HoverEffectImage
                        imgSize={imgSize}
                        className={clsx(product.attributes && (product.attributes.notShow || product.attributes.cats.data.length === 0 || notShowCat) && 'brightness-35 bg-gray-50', 'w-full max-h-104 object-contain border border-solid border-gray-11 rounded-xl')}
                        defImage={getImage(product).default}
                        altImage={getImage(product).alternative}
                        altText={product ? product.attributes.name : 'IMAGE'}
                    />
                ) : (
                    <Image
                        className={clsx(product.attributes && (product.attributes.notShow || product.attributes.cats.data.length === 0 || notShowCat) && 'brightness-35 bg-gray-50', 'w-full max-h-104 object-contain border border-solid border-gray-11 rounded-xl')}
                        src={getImage(product).default}
                        alt={product ? product.attributes.name : 'IMAGE'}
                        sizes="100vw"
                        style={{
                            width: '100%',
                            height: 'auto',
                        }}
                        quality={imgSize.quality}
                        width={imgSize.width}
                        height={imgSize.height}
                    />
                )}
                {getPriceDiscount(product) && (
                    <div className={clsx("flex absolute h-6 laptop:h-8 w-full bottom-0.5 laptop:bottom-px left-0 items-center justify-center text-white font-normal bg-pink-3 border border-t-0 border-solid rounded-b-xl opacity-[.85]")}>
                        <img src='/assets/showcase/images/flame.svg' alt='FIRE'/>
                        <span className='ml-1 text-xs tablet:text-sm'>Дешевле на {getPriceDiscount(product)}%</span>
                    </div>
                )}
                <div className={clsx(product.attributes && (product.attributes.notShow || product.attributes.cats.data.length === 0 || notShowCat) ? 'flex flex-col' : 'hidden', 'absolute inset-x-2 tablet:inset-x-3 inset-y-20 tablet:inset-y-32 laptop:inset-y-44 text-sm tablet:text-base text-center text-white leading-5' )}>
                    <IconFlaticon className={'text-white text-3xl'} name={'rr-eye-crossed'}/>
                    <div className={'tablet:px-2'}>
                        {product.attributes.notShow && 'Товар скрыт администратором'}
                    </div>
                    <div className={'tablet:px-2'}>
                        {product.attributes.cats.data.length === 0 && 'Товар скрыт - категория товара не выбрана'}
                    </div>
                    <div className={'tablet:px-2'}>
                        {notShowCat && 'Товар скрыт - категория скрыта'}
                    </div>
                </div>
            </GetLink>
            <GetLink>
                <div className={clsx('flex flex-col space-y-1', singleRow ? 'mb-0.5' : 'mb-1')}>
                    <div className={clsx('text-sm tablet:text-base font-semibold truncate whitespace-break-spaces', singleRow ? 'h-6 truncate' : 'max-h-10 laptop:max-h-12')}>
                        {product && product.attributes.name}
                    </div>
                    <PriceLabel product={product} singleRow={singleRow}/>
                </div>
            </GetLink>
            <div className="flex h-full items-end">
                {user && user.isLoggedIn ? (
                    <StyledButton
                        href={`/showcase/product/`+product.id}
                        component={NextLink}
                        className={'flex flex-row px-8 justify-center items-center text-sm tablet:text-base'}
                        colorScheme={colorScheme}
                        text={<><IconFlaticon className={'hidden tablet:flex mr-3'} name={'sr-pencil'}/>Редактировать</>}
                    />
                ) : (
                    <AddCartProduct slug={getSlugHref()} product={product} colorScheme={colorScheme} className='justify-end' />
                )}
            </div>
        </div>
    )
}
