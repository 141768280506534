import * as React from 'react';
import clsx from 'clsx'
import Popper from '@mui/material/Popper';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import IconFlaticon from "@/components/IconFlaticon";
import { motion, Variants } from "framer-motion";
import {StyledRadioButton, StyledRadioGroup} from "@/components/showcase/_controls";
import {getThemeColors} from "@/components/showcase/_helpers";


export default function RatingFilter({sortPrice, handleChangeSortPrice, urlPrice, colorScheme}) {
    const anchorRef = React.useRef(null);
    const [open, setOpen] = React.useState(false);
    // const StyledRadioButton = styled(Radio) ({
    //     color: '#CBCBCB',
    //     '&.Mui-checked': {
    //         color: getThemeColors(colorScheme),
    //     },
    //     '&.MuiTypography-body1': {
    //         width: '100%'
    //     }
    // })

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
          return;
        }
        setOpen(false);
    };

    function getLabel(){
        let label = 'По популярности'
        switch (sortPrice) {
            case 'disc':
                label = 'Сначала со скидкой'
                break;
            case 'asc':
                label = 'По возрастанию цены'
                break;
            case 'desc':
                label = 'По убыванию цены'
                break;
        
            default:
                break;
        }
        return label
    }

    return(
        <div className={'text-sm laptop:text-base'}>
            <div ref={anchorRef}>
                <motion.div
                    className={clsx('flex flex-row h-10 px-5 items-center space-x-3 bg-white-6 hover:bg-gray-100 rounded-12 cursor-pointer')}
                    whileTap={{ scale: 0.97 }}
                    onClick={() => {
                        setOpen(!open)
                    }}
                >
                    <IconFlaticon className='mt-px' name='sr-sort-alt' />
                    <div className='text-black'>{getLabel()}</div>
                    <IconFlaticon className='pt-2 text-gray-9 text-sm' name={open ? 'br-angle-up' : 'br-angle-down'} />
                </motion.div>
            </div>
            <Popper      
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="bottom-start"
                transition
                style={{ zIndex: 10 }}
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                            placement === 'bottom-start' ? 'left top' : 'left top',
                        }}
                    >
                        <Paper className='bg-white mt-2.5 w-84 rounded-14' elevation={8}>
                            <StyledRadioGroup
                                className={"px-4 py-3.5"}
                                value={sortPrice}
                                onChange={(e)=> {
                                    handleChangeSortPrice(e)
                                    setOpen(!open);
                                }}
                                buttonsColor={'#CBCBCB'}
                                buttonsCheckedColor={getThemeColors(colorScheme)}
                                onClickAway={handleClose}
                                options={[
                                    {
                                        value: '',
                                        name: 'По популярности'
                                    },
                                    {
                                        value: 'asc',
                                        name: 'По возрастанию цены'
                                    },
                                    {
                                        value: 'desc',
                                        name: 'По убыванию цены'
                                    },
                                    {
                                        value: 'disc',
                                        name: 'Сначала со скидкой'
                                    }
                                ]}
                            />
                        </Paper>
                    </Grow>
                )}
            </Popper>
        </div>
    )
}