import * as React from "react";
import { memo } from 'react';
import clsx from "clsx";
import 'swiper/swiper.min.css';
import { Autoplay, Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { styled } from "@mui/material/styles";
import CircularProgress from '@mui/material/CircularProgress';
import { getThemeColors } from "@/components/showcase/_helpers";
import IconFlaticon from "@/components/IconFlaticon";

const StyledSwiper = styled(Swiper)(({ theme, colorscheme }) => ({
    '& .swiper-pagination': {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop:16,
        zIndex: 100,
        '& .swiper-pagination-bullet':{
            width: '0.5rem',
            height: '0.5rem',
            marginLeft: '0.25rem',
            borderRadius: '9999px',
            backgroundColor: '#E2E2E2',
            '&:only-child':{
                display:'none'
            }
        },
        // '& .first':{
        //     marginLeft: '8rem'
        // },
        '& .swiper-pagination-bullet-active': {
            width: '0.75rem',
            height: '0.75rem',
            backgroundColor: getThemeColors(colorscheme)
        }
    }
}))

function BannerSlider({ swiperRef, user, bannerImages, hideLoading, deleteLoading, shop, handleClickOpen, handleClickOpenHide, setImageTemp, colorScheme  }){

    const pagination = {
        clickable: true,
        renderBullet: function (index, className) {
            if(index === 0){
                className += ' first'
            }
            return '<div class="cursor-pointer ' + className + '"></div>';
        },
    };

    return(
        <StyledSwiper
            colorscheme={colorScheme}
            className="w-full"
            pagination={pagination}
            modules={[Navigation, Pagination, Autoplay]}
            onBeforeInit={(swiper) => {
                swiperRef.current = swiper;
            }}
            // Default parameters
            slidesPerView="auto"
            spaceBetween={28}
            loop
            speed={1500}
            autoplay={{
                delay: 5000,
            }}
        >
            {user && user.isLoggedIn && (shop.id === user.id) ? (
                bannerImages.length > 0 && (
                    bannerImages.map((ban, n) => {
                        let urlBanner = null
                        if(ban.image.url){
                            urlBanner = `${process.env.NEXT_PUBLIC_UPLOAD_STRAPI_URL}${ban.image.url}?format=webp&resize=1200x400&embed&quality=100`
                        }
                        if(!urlBanner){
                            return <></>
                        }
                        return(
                            <SwiperSlide className='w-full' key={`banner${n}`}>
                                <div style={{ backgroundImage: `url(${urlBanner})`, backgroundPosition: 'center'}} className={clsx(!ban.is_show && 'brightness-35', 'flex flex-row justify-end w-auto h-28 laptop:h-100 mx-3 laptop:mx-0 bg-cover rounded-xl')}/>
                                <div className={clsx(ban.is_show ? 'hidden' : 'absolute', 'flex flex-col inset-1/3 space-y-3 py-5 items-center text-white')}>
                                    <IconFlaticon className={'text-4xl'} name={'rr-eye-crossed'}/>
                                    <div className={'px-2'}>
                                        Баннер скрыт администратором
                                    </div>
                                </div>
                                <div className='absolute flex flex-col top-0 right-0 py-3 tablet:py-4 laptop:py-6 pr-6 laptop:pr-6 space-y-4'>
                                    {/* <div className='flex w-9 h-9 items-center justify-center bg-white text-gray-9 rounded-full shadow-lg cursor-pointer'>
                                            <IconFlaticon name={'sr-pencil'} className={''}/>
                                        </div> */}
                                    {hideLoading.id === ban.id ? (
                                        <div className='flex w-9 h-9 items-center justify-center'>
                                            <CircularProgress color="error" />
                                        </div>
                                    ):(
                                        <div
                                            onClick={()=> {
                                                setImageTemp(ban)
                                                handleClickOpenHide()
                                            }}
                                            className={clsx(!ban.is_show ? 'bg-pink-2 text-white' : 'bg-white hover:bg-pink-2 text-gray-9 hover:text-white', 'flex w-9 h-9 items-center justify-center rounded-full shadow-lg cursor-pointer')}
                                        >
                                            <IconFlaticon name={!ban.is_show ? 'sr-eye-crossed' : 'sr-eye'} className={'text-xl pl-px pt-1'}/>
                                        </div>
                                    )}
                                    {deleteLoading.id === ban.id ? (
                                        <div className='flex w-9 h-9 items-center justify-center'>
                                            <CircularProgress />
                                        </div>
                                    ):(
                                        <div
                                            onClick={()=>{
                                                setImageTemp(ban)
                                                handleClickOpen()
                                            }}
                                            //onClick={async ()=> await sendDel(product.id)}
                                            className='flex w-9 h-9 items-center justify-center bg-white hover:bg-pink-2 text-gray-9 hover:text-white rounded-full shadow-lg cursor-pointer'
                                        >
                                            <IconFlaticon name={'sr-trash'} className={''}/>
                                        </div>
                                    )}
                                </div>
                            </SwiperSlide>
                        )
                    })
                )
            ):(
                shop.banners.length > 0 && (
                    shop.banners.filter((e)=> e.is_show === true).map((ban, n) => {
                        let urlBanner = null
                        if(ban.image.url){
                            urlBanner = `${process.env.NEXT_PUBLIC_UPLOAD_STRAPI_URL}${ban.image.url}?format=webp&resize=1200x400&embed&quality=100`
                        }
                        if(!urlBanner){
                            return <></>
                        }
                        return(
                            <SwiperSlide className='w-full' key={`banner${n}`}>
                                <div style={{ backgroundImage: `url(${urlBanner})`, backgroundPosition: 'center'}} className="flex flex-row justify-end w-auto h-28 laptop:h-100 mx-3 laptop:mx-0 bg-cover rounded-xl"></div>
                            </SwiperSlide>
                        )
                    })
                )
            )}
        </StyledSwiper>
    )
}

export default memo(BannerSlider)




