import * as React from 'react';
import clsx from 'clsx'
import Link from '../../../../theme/Link';
import IconFlaticon from '../../../IconFlaticon';
import {correctEndings, getThemeColors} from "@/components/showcase/_helpers";
import useUser from '../../../../../lib/useUser';
import {ButtonTransparent, StyledLink} from "@/components/showcase/_controls";
import NextLink from "next/link";

export default function CatsNestedList({ user, notShowId, cats, activeCat, top, meta, colorScheme }) {
    
    const themeColor = getThemeColors(colorScheme ? colorScheme : 0);

    function getParentIds(cat, ids) {
        if(cat){
            const id = cat.attributes?.parent?.data?.id || null
            const data = cat.attributes?.parent?.data || null
            if(id){
                return [...ids, cat.id, ...getParentIds(data, ids)]
            }else{
                return [...ids, cat.id]
            }
        }
        return []
    }

    function getParentSlugs(cat, slugs) {
        if(cat){
            const slug = cat.attributes?.parent?.data?.attributes?.slug || null
            const data = cat.attributes?.parent?.data || null
            if(slug){
                return [...slugs, cat.attributes.slug, ...getParentSlugs(data, slugs)]
            }else{
                return [...slugs, cat.attributes.slug]
            }
        }
        return []
    }

    const ids = getParentIds(activeCat, [])
    const slugs = getParentSlugs(activeCat, [])

    const GetCat = ({ cat, slug, child, level, pad }) => {
        
        const check = activeCat ? (ids.includes(cat.id)) : false
        const activeCheck = activeCat ? activeCat.id === cat.id : false
        const [open, setOpen] = React.useState(check);
        const handleClick = () => {
            setOpen(!open);
        };
        const checkLevel = level > 0
        let temp = ''
        for (let i = 0; i < level; i++) {
            temp += '-'
        }
//{clsx(check ? 'text-lime-600' : 'text-neutral-800', 'hover:text-lime-600  py-1.5 leading-none font-medium ')}
        function getCssActive(check, active) {
            let css = 'text-neutral-900 hover:text-lime-600' 
            if(check){
                css = 'text-lime-600'
            }
            if(active){
                css = 'rounded-lg px-3 bg-lime-200 py-2 text-neutral-800'
            }          
            css += ' leading-5'
            return css
        }

        return(
            <React.Fragment>
                <div className={clsx(pad && `ml-3`, 'flex flex-col space-y-3 ')}>
                {child && child.length > 0  ? (
                    <>
                        <Link noLinkStyle href={slug} className='py-0.5'>
                            <div className={clsx('flex flex-row items-center space-x-1.5')}>
                                {(open && !activeCheck) && <IconFlaticon className='-ml-1 text-lime-600' name='br-angle-left' /> }
                                <span className={clsx(getCssActive(check, activeCheck))}>{cat.attributes.name}</span>
                            </div>
                        </Link>
                        {check && child.map((catT, n)=> 
                            <React.Fragment key={n}>
                                <GetCat 
                                    cat={catT} 
                                    slug={`${slug}/${catT.attributes.slug}`} 
                                    child={catT.attributes?.child?.data}  
                                    level={level+1}
                                    pad
                                />
                            </React.Fragment>
                        )}
                    </>
                ) : (
                        <Link noLinkStyle href={slug} className='py-0.5'>
                            <div className='flex flex-row items-center'>
                                <span className={clsx(getCssActive(check, activeCheck))}>{cat.attributes.name}</span>
                            </div>
                        </Link>
                )}
                </div>
            </React.Fragment>
        )
    }

    function getActive(cat){
        return activeCat ? (activeCat.id === cat.id) || (ids.includes(cat.id)) : false
    }

    const GetHideProduct = ({ type }) => {
        if(type === 'cat'){
            return(
                <Link 
                    noLinkStyle 
                    scroll={false}
                    href={notShowId === 'cat' ? '/'  : `/not-show/cat`}
                >
                    <div style={ notShowId === 'cat' ? {backgroundColor: themeColor} : {}} className={clsx(notShowId === 'cat' ? 'text-white' : 'text-black', 'flex flex-row h-10 px-5 items-center bg-white-6 rounded-xl hover:brightness-90 ')}>
                        Товары без категорий
                    </div>
                </Link>
            )
        }else if(type === 'hide'){
            return(
                <Link 
                    noLinkStyle 
                    scroll={false}
                    href={notShowId === 'hide' ? '/' : `/not-show/hide`}
                >
                    <div style={ notShowId === 'hide' ? {backgroundColor: themeColor} : {}} className={clsx(notShowId === 'hide' ? 'text-white' : 'text-black', 'flex flex-row h-10 px-5 items-center bg-white-6 rounded-xl hover:brightness-90 ')}>
                        Скрытые товары
                    </div>
                </Link>
            )
        }
    }

    if(top){
        slugs.reverse()
        const checkUser = user && user.isLoggedIn
        const childs = activeCat ? (checkUser ?  activeCat.attributes.child.data : activeCat.attributes.child.data.filter((e) => e.attributes.notShow !== true)) : cats.filter((e)=> e.attributes.parent.data === null)
        const slug = activeCat ?  `/${slugs.join('/')}` : ''
        return(
            <div>
                <div className='flex flex-row space-x-4 items-center leading-none'>
                    <div className='text-3xl font-black'>
                        {activeCat ? activeCat.attributes.name : 'Все товары'}
                    </div>
                    {meta && (
                        <div className='text-gray-9'>{meta.pagination.total} {correctEndings(meta.pagination.total,['товар','товара','товаров'])}</div>
                    )}
                </div>
                <div className='flex flex-wrap items-center pt-3'>
                    {childs && childs.length > 0  && (
                        childs.map((catT, n)=> 
                            <React.Fragment key={n}>
                                { n > 0 && (
                                    <div className='text-gray-9 px-2.5 text-sm leading-none'>/</div>
                                )}
                                <StyledLink
                                    href={`${slug}/${catT.attributes.slug}`}
                                    className={'text-gray-9 py-1.5 leading-none'}
                                    colorScheme={colorScheme}
                                    text={
                                        <div className='whitespace-nowrap flex flex-row items-center'>
                                            {catT.attributes.name}
                                        </div>
                                    }
                                />
                            </React.Fragment>
                        )
                    )}
                </div>
                {user && user.isLoggedIn && (
                     <div className='flex flex-wrap pt-3 items-center space-x-4'>
                         <ButtonTransparent href={`/showcase/product/`} component={NextLink} className='flex flex-row h-10 px-8 text-white text-sm tablet:text-base bg-purple-1 hover:bg-purple-1 hover:brightness-90 rounded-xl'>
                             <IconFlaticon className={'flex mr-3'} name={'br-plus'}/>
                             Добавить товар
                         </ButtonTransparent>
                         <GetHideProduct type="hide" />
                         <GetHideProduct type="cat" />
                    </div>
                )}
            </div>
        )
    }

    return (
        <div className='flex flex-col space-y-3'>
            <div className='pb-1'>
                <Link noLinkStyle href='/' className='font-bold text-neutral-800'>
                    Категория
                </Link>
            </div>
            {cats.filter((e)=> e.attributes.parent.data === null).map((cat, n)=> 
                <React.Fragment key={n}>
                    <GetCat 
                        cat={cat} 
                        slug={`/${cat.attributes.slug}`} 
                        child={cat.attributes.child.data.filter((e) => e.attributes.notShow !== true)}  
                        level={0}
                    />
                </React.Fragment>
            )}
        </div>
    );
}