import * as React from 'react';
import { useMemo, useState } from 'react';
import { motion } from 'framer-motion';
import { styled } from '@mui/material/styles';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import IconFlaticon from '../IconFlaticon';
import clsx from 'clsx';

const AccordionCustom = styled(Accordion)(({ theme }) => ({
    background: "transparent",
    // '& .MuiAccordionSummary-expandIconWrapper':{
    //     margin: '12px 0',
    // },
    // borderBottom: `2px solid #F8F8F8`,
    '&:before': {
        display: 'none',
    },
    '& .MuiAccordionSummary-root':{
        padding: '1rem 0',
        [theme.breakpoints.down('lg')]: {
            padding: '0rem 1rem',
        },
    },
    '& .MuiAccordionDetails-root': {
        padding: '0px 0 2rem 0',
        [theme.breakpoints.down('lg')]: {
            padding: '0px 1rem 1rem 1rem',
        },
    }
}))

const faqDB = [
    {
        id: 1, name: 'Частые вопросы', elements: [
            {
                id: '1',
                question: 'Почему выбирают ecommy?',
                answer:
                    'Это лучшее решение, если вы хотите начать интернет-продажи уже сегодня. Вам не нужно вникать в технические детали создания сайта, переплачивать за личный интернет-магазин, запасаться терпением на разработку и доработки — вы сразу получаете все необходимые инструменты для продаж. Мы используем только современные инструменты программирования, а значит магазин работает быстро, хорошо считывается в поисковых системах, и процент отказов покупателей снижается.'
            },
            {
                id: '2',
                question: 'Как быстро запустится магазин?',
                answer:
                    'Мы настроим адрес (домен) вашего магазина в течение часа после регистрации, и вам придет уведомление, что магазин готов к работе. Вы сможете начать продавать даже без дополнительных настроек — в форме регистрации мы собираем всю необходимую информацию для запуска.'
            },
            {
                id: '3',
                question: 'Что входит в аренду?',
                answer:
                    'Помимо самого интернет-магазина, в стоимость входит хостинг, обслуживание, хранение данных, техподдержка и добавление нового функционала. Нет скрытых комиссий и навязанных дополнительных услуг.'
            },
            {
                id: '4',
                question: 'Сколько длится аренда?',
                answer:
                    'Вы можете оплатить аренду на срок 30, 90, 180 или 360 дней. Чем больше срок аренды, тем выгоднее скидка.'
            },
            {
                id: '5',
                question: 'Берется ли комиссия за продажи?',
                answer:
                    'Нет, ecommy не взимает комиссию с продажи ваших товаров.'
            },
            {
                id: '6',
                question: 'Можно ли запускать рекламу на этот магазин?',
                answer:
                    'Да, вы можете настраивать и запускать таргетированную рекламу на адрес своего магазина, отдельные товары или группы товаров. Запускать рекламу можно в поисковых системах, а также на любой площадке, где принимаются ссылки на внешние сайты (социальные сети, мессенджеры).'
            },
            {
                id: '7',
                question: 'Что если мне нужен дополнительный функционал?',
                answer:
                    'Свяжитесь с нами и расскажите подробно, какие интеграции или инструменты вы хотели бы видеть у себя в интернет-магазине. Мы открыты к доработкам, а значит рассмотрим заявку и сформируем индивидуальное предложение.'
            }
        ]
    }
]

function LandingAccordion() {
    const [expanded, setExpanded] = useState(null);

    const toggleAccordion = (panel) => (event, _expanded) => {
        setExpanded(_expanded ? panel : false);
    };

    return (
        <div className="w-full flex flex-col">
            {useMemo(() => {
                const item = {
                    hidden: { opacity: 0, y: 20 },
                    show: { opacity: 1, y: 0 },
                };

                return (
                    faqDB.map((faqEl, index) => (
                        <div key={faqEl.id}>
                            {faqEl.elements.map((faq) => (
                                <AccordionCustom
                                    disableGutters
                                    component={motion.div}
                                    variants={item}
                                    key={faq.id}
                                    classes={{
                                        root: 'shadow-none border-none',
                                    }}
                                    expanded={expanded === faq.id}
                                    onChange={toggleAccordion(faq.id)}
                                >
                                    <AccordionSummary
                                        className="items-center border-t border-dashed border-gray-22 border-opacity-40"
                                        expandIcon={
                                            <IconFlaticon
                                                className={clsx(expanded === faq.id && 'text-purple-1', 'laptop:text-lg')}
                                                name="br-angle-down"
                                            />
                                        }
                                    >
                                        <h4 className={clsx('flex items-center text-sm laptop:text-2xl font-normal', expanded === faq.id && 'text-purple-1')} >
                                            {faq.question}
                                        </h4>
                                    </AccordionSummary>

                                    <AccordionDetails>
                                        <p className="max-w-5xl pr-10 text-sm laptop:text-lg">{faq.answer}</p>
                                    </AccordionDetails>
                                </AccordionCustom>
                            ))}
                        </div>
                    ))
                );
            }, [expanded])}
        </div>
    )
}

export default LandingAccordion