import * as React from 'react';
import clsx from 'clsx';
import { useForm  } from "react-hook-form";
import Popper from '@mui/material/Popper';
import Button from '@mui/material/Button';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import IconFlaticon from "@/components/IconFlaticon";
import { motion, Variants } from "framer-motion";
import isEmpty from 'lodash/isEmpty';
import trim from 'lodash/trim';
import {StyledButton, StyledInput} from "@/components/showcase/_controls";

export default function PriceFilter({
    handleChangeFilterPrice,  
    filterPriceStart,
    setFilterPriceStart,
    filterPriceEnd,
    setFilterPriceEnd,
    colorScheme
}) {
    const { control, register, handleSubmit, setError, clearErrors, setValue, formState: { errors } } = useForm();
    const anchorRef = React.useRef(null);
    const [open, setOpen] = React.useState(false);

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
          return;
        }
    
        setOpen(false);
    };

    function getLabel(){
        let label = ''
        if(filterPriceStart > 0){
            label += `от ${filterPriceStart.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}₽`
        }
        if(filterPriceEnd > 0){
            label += ` до ${filterPriceEnd.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ")}₽`
        }
        if(isEmpty(label)){
            label = 'Цена'
        }
        return trim(label)
    }

    async function onSubmit(data){
        setOpen(!open)
        setFilterPriceEnd(data.end)
        setFilterPriceStart(data.start)
        handleChangeFilterPrice(data.start, data.end)
    }

    return(
        <div className={'text-sm laptop:text-base'}>
            <div ref={anchorRef}>
                <motion.div
                    className={clsx('flex flex-row max-w-40 h-10 px-5 items-center space-x-3 bg-white-6 hover:bg-gray-100 rounded-12 cursor-pointer')}
                    whileTap={{ scale: 0.97 }}
                    onClick={() => {
                        setOpen(!open)
                    }}
                >
                    <span className='text-black truncate'>{getLabel()}</span>
                    <IconFlaticon className='pt-2 text-gray-9 text-sm' name={open ? 'br-angle-up' : 'br-angle-down'} />
                </motion.div>
            </div>
            <Popper      
                open={open}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="bottom-end"
                transition
                style={{ zIndex: 10 }}
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin:
                            placement === 'bottom-end' ? 'right top' : 'left top',
                        }}
                    >
                        <form noValidate onSubmit={handleSubmit(onSubmit)}>
                            <Paper className='bg-white mt-2.5 w-84 rounded-14' elevation={8}>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <div className='flex flex-col px-4 py-3.5 space-y-4'>
                                        <div className='flex flex-row space-x-3'>
                                            <StyledInput
                                                title={'От'}
                                                name={'start'}
                                                defaultValue={filterPriceStart}
                                                className={'bg-white-6 hover:bg-white'}
                                                error={errors.start}
                                                register={register}
                                                required={false}
                                                hintMargins={'mt-1'}
                                                colorScheme={colorScheme}
                                                onKeyDown={(e) => {
                                                    if(![8,37,39,46,48,49,50,51,52,53,54,55,56,57,96,97,98,99,100,101,102,103,104,105].includes(e.keyCode)){
                                                        e.preventDefault();
                                                    }
                                                }}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            className={'bg-transparent hover:bg-transparent'}
                                                            onClick={()=> setValue('start', '')}
                                                            edge="end"
                                                            size="small"
                                                        >
                                                            <IconFlaticon className='h-4.5 text-gray-4 hover:text-black-1' name='br-cross-small' />
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                            <StyledInput
                                                title={'До'}
                                                name={'end'}
                                                defaultValue={filterPriceEnd}
                                                className={'bg-white-6 hover:bg-white'}
                                                error={errors.end}
                                                register={register}
                                                required={false}
                                                hintMargins={'mt-1'}
                                                colorScheme={colorScheme}
                                                onKeyDown={(e) => {
                                                    if(![8,37,39,46,48,49,50,51,52,53,54,55,56,57,96,97,98,99,100,101,102,103,104,105].includes(e.keyCode)){
                                                        e.preventDefault();
                                                    }
                                                }}
                                                endAdornment={
                                                    <InputAdornment position="end">
                                                        <IconButton
                                                            className={'bg-transparent hover:bg-transparent'}
                                                            onClick={()=> setValue('end', '')}
                                                            edge="end"
                                                            size="small"
                                                        >
                                                            <IconFlaticon className='h-4.5 text-gray-4 hover:text-black-1' name='br-cross-small' />
                                                        </IconButton>
                                                    </InputAdornment>
                                                }
                                            />
                                        </div>
                                        <StyledButton
                                            type="submit"
                                            text={'Применить'}
                                            colorScheme={colorScheme}
                                        />
                                    </div>
                                </ClickAwayListener>
                            </Paper>
                        </form>
                    </Grow>
                )}
            </Popper>
        </div>
    )
}