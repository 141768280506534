import * as React from 'react';
import clsx from 'clsx';
import NextLink from 'next/link';
import {ButtonGray, ButtonTransparent} from "@/components/showcase/_controls";
import IconFlaticon from "@/components/IconFlaticon";
import useUser from '../../../../../lib/useUser';

export default function AddProduct({ user }){
    if(!user?.isLoggedIn){
        return <></>
    }
    return(
        <div className={clsx('z-20 fixed tablet:hidden bottom-0 w-full px-4 py-2.5 bg-white border-0 border-t border-solid border-white-1')}>
            <ButtonTransparent href={`/showcase/product/`} component={NextLink} className='w-full h-10 text-white text-sm tablet:text-base bg-purple-1 hover:bg-purple-1 hover:brightness-90 rounded-xl'>
                Добавить товар
            </ButtonTransparent>
        </div>
        // <div className={clsx('flex flex-col space-y-3.5 mb-4')}>
        //     <img className='w-full h-auto rounded-3xl' src='/assets/showcase/images/shop-add-product-bg.svg' alt="IMAGE"/>
        //     <div className="flex h-full items-end">
        //         <ButtonGray href={`/showcase/product/`} component={NextLink} className='flex flex-row w-full justify-center items-center text-white text-sm tablet:text-base bg-gradient-to-r from-pink-1 to-purple-1 hover:brightness-90'>
        //             <IconFlaticon className={'flex mr-4 text-xl'} name={'br-plus'}/>
        //             Добавить товар
        //         </ButtonGray>
        //     </div>
        // </div>
    )
}