import {memo} from 'react';
import {StyledDialog} from "@/components/showcase/_controls";
import IconFlaticon from "@/components/IconFlaticon";
import useMediaQuery from "@mui/material/useMediaQuery";

const BannerDialog = memo(({ open, handleClose, openHide, handleCloseHide, getIndex, imageTemp, sendDel, sendHide }) => {
    const screenMobile = useMediaQuery('(max-width:640px)');
    return(
        <>
            <StyledDialog
                open={open}
                onClose={handleClose}
                ariaLabell="Удаление баннера"
                ariaDescribedBy="Удаление баннера"
                title={<><div>Вы уверены, что хотите удалить баннер №{getIndex()}?</div><div>Это действие нельзя отменить.</div></>}
                buttonTitle={<><IconFlaticon className={'mt-1.5 mr-4'} name={'sr-trash'}/>{screenMobile ? 'Да' : 'Да, удалить'}</>}
                buttonClass={'bg-pink-2 hover:bg-pink-2'}
                buttonSuccess={async ()=> await sendDel(imageTemp)}
                buttonDecline={handleClose}
            />
            <StyledDialog
                open={openHide}
                onClose={handleCloseHide}
                ariaLabell="Скрыть баннер"
                ariaDescribedBy="Скрыть баннер"
                title={!imageTemp?.is_show ? `Вы уверены, что хотите показать баннер №${getIndex()}?` : `Вы уверены, что хотите скрыть баннер №${getIndex()}?`}
                buttonTitle={!imageTemp?.is_show ? <><IconFlaticon className={'mt-1.5 mr-4'} name={'sr-eye'}/>{screenMobile ? 'Да' : 'Да, показать'}</> : <><IconFlaticon className={'mt-1.5 mr-4'} name={'sr-eye-crossed'}/>{screenMobile ? 'Да' : 'Да, скрыть'}</>}
                buttonSuccess={async ()=> await sendHide(imageTemp)}
                buttonDecline={handleCloseHide}
            />
        </>
    )
})

export default BannerDialog