import * as React from "react";
import { useSnackbar } from 'notistack';
import { useRouter } from 'next/router'
import NextLink from "next/link";
import clsx from "clsx";
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogTitle from '@mui/material/DialogTitle';
import CircularProgress from '@mui/material/CircularProgress';
import {ButtonGray, StyledButton, StyledDialog} from "@/components/showcase/_controls";
import IconFlaticon from "../../../IconFlaticon"
import { axiosTemplate } from "../../../../../lib/fetchJson";
import useMediaQuery from "@mui/material/useMediaQuery";


export default function ProductPanel({ small, product, user, noEdit, refresh, colorScheme }){
    const { enqueueSnackbar } = useSnackbar();
    const { replace, push, asPath, pathname } = useRouter();
    const [deleteLoading, setDeleteLoading] = React.useState({ id: null })
    const [hideLoading, setHideLoading] = React.useState({ id: null })
    const [open, setOpen] = React.useState(false);
    const [openHide, setOpenHide] = React.useState(false);
    const screenMobile = useMediaQuery('(max-width:640px)');

    const handleClickOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }

    const handleClickOpenHide = () => {
        setOpenHide(true)
    }
    const handleCloseHide = () => {
        setOpenHide(false)
    }

    async function sendDel(id){
        handleClose()
        setDeleteLoading({ id })
        if(product.attributes.images.data && product.attributes.images.data.length > 0){
            product.attributes.images.data.forEach(async (delImage)=>{
                await axiosTemplate(`${process.env.NEXT_PUBLIC_UPLOAD_STRAPI_URL}/api/upload/files/${delImage.id}`, "DELETE", null, true, user.token)
            })
        }
        await axiosTemplate(`${process.env.NEXT_PUBLIC_UPLOAD_STRAPI_URL}/api/products/${id}`, "DELETE", null, true, user.token)
        enqueueSnackbar(`Товар "${product.attributes.name}" удален`, { variant: "success" });
        await push('/')
        setDeleteLoading({ id: null })
    }

    async function sendHide(id, notShow){
        handleCloseHide()
        setHideLoading({ id })
        const response = await axiosTemplate(`${process.env.NEXT_PUBLIC_UPLOAD_STRAPI_URL}/api/products/${id}`, "PUT", { data: { notShow: !notShow } }, true, user.token)
        if(noEdit){
            await refresh()
        }else{
            await replace(asPath, asPath, {scroll: false})
        }
        enqueueSnackbar(`Товар "${product.attributes.name}" ${!notShow ? 'скрыт' : 'показан' }`, { variant: "success" });
        setHideLoading({ id: null })
    }

    const size = small ? `w-7 h-7 tablet:w-9 tablet:h-9`: `w-10 tablet:w-11 h-10 tablet:h-11`
    return(
        <div className={clsx(small ? 'space-x-2 tablet:space-x-4' : 'space-x-4','flex flex-row')}>
            {!noEdit && (
                small ? (
                    <NextLink href={`/showcase/product/${product.id}`} title={'Редактировать товар'} className={clsx('w-7 h-7 tablet:w-9 tablet:h-9 flex items-center justify-center bg-gray-2 hover:bg-purple-1 text-gray-9 hover:text-white rounded-full')}>
                        <IconFlaticon className={clsx(small && 'text-sm tablet:text-base', 'flex')} name={'sr-pencil'} />
                    </NextLink>
                ):(
                    <StyledButton
                        href={`/showcase/product/${product.id}`}
                        component={NextLink}
                        className={'flex flex-row w-auto h-10 tablet:h-11 px-6 justify-center items-center text-sm tablet:text-base'}
                        text={<><IconFlaticon className={'mr-3 -mb-1'} name={'sr-pencil'}/>Редактировать</>}
                        colorScheme={colorScheme}
                    />
                )
            )}
            {hideLoading.id === product.id ? (
                <div className={`flex ${size} items-center justify-center`}>
                    <CircularProgress color="error" />
                </div>
            ):(
                <div title={product.attributes.notShow ? 'Показать товар' : 'Скрыть товар'} className={clsx(product.attributes.notShow ? 'bg-pink-2 text-white' : 'bg-gray-2 hover:bg-pink-2 text-gray-9 hover:text-white', `flex ${size} items-center justify-center  rounded-full cursor-pointer`)} onClick={handleClickOpenHide}>
                    <IconFlaticon name={product.attributes.notShow ? 'sr-eye-crossed' : 'sr-eye'} className={clsx(small? 'tablet:pl-px text-base tablet:text-xl' : 'pl-px text-xl', 'flex items-center justify-center')}/>
                </div>
            )}
            {deleteLoading.id === product.id ? (
                <div className={`flex ${size} items-center justify-center`}>
                    <CircularProgress />
                </div>
            ):(
                <div title={'Удалить товар'} className={`flex ${size} items-center justify-center bg-gray-2 hover:bg-pink-2 text-gray-9 hover:text-white rounded-full cursor-pointer`} onClick={handleClickOpen}>
                    <IconFlaticon name={'sr-trash'} className={clsx(small ? 'tablet:pl-px text-sm tablet:text-base' : 'pl-px text-base', 'flex items-center justify-center')}/>
                </div>
            )}
            <StyledDialog
                open={open}
                onClose={handleClose}
                ariaLabell="Удаление товара"
                ariaDescribedBy="Удаление товара"
                title={<><div>Вы уверены, что хотите удалить товар?</div><div>Это действие нельзя отменить.</div></>}
                buttonTitle={<><IconFlaticon className={'mt-1.5 mr-4'} name={'rr-trash'}/>{screenMobile ? 'Да' : 'Да, удалить'}</>}
                buttonClass={'bg-pink-2 hover:bg-pink-2'}
                buttonSuccess={async ()=> await sendDel(product.id)}
                buttonDecline={handleClose}
            />
            <StyledDialog
                open={openHide}
                onClose={handleCloseHide}
                ariaLabell="Скрыть товар"
                ariaDescribedBy="Скрыть товар"
                title={product.attributes.notShow ? "Вы уверены, что хотите показать товар?" : "Вы уверены, что хотите скрыть товар?"}
                buttonTitle={product.attributes.notShow ? <><IconFlaticon className={'mt-1.5 mr-4'} name={'sr-eye'}/>{screenMobile ? 'Да' : 'Да, показать'}</> : <><IconFlaticon className={'mt-1.5 mr-4'} name={'sr-eye-crossed'}/>{screenMobile ? 'Да' : 'Да, скрыть'}</>}
                buttonSuccess={async ()=> await sendHide(product.id, product.attributes.notShow)}
                buttonDecline={handleCloseHide}
            />
        </div>
    )

}