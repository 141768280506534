
import NextLink from "next/link";
import { StyledButton } from "./showcase/_controls";
import Button from "@mui/material/Button";


export default function BlockedLayout(){

    return(
        <div className="w-full max-w-160 flex flex-col h-screen items-center justify-center space-y-6 sm:space-y-16 px-4 sm:px-0">
            <img src={'/assets/showcase/images/blocked.svg'} alt={'blocked'}/>
            <div className="text-blocked pt-6 text-2xl sm:text-4xl font-black text-center leading-10 sm:leading-14">
                Здесь когда-то был магазин, но сейчас он не работает
            </div>
            <Button
                sx={{ textTransform: 'none', fontSize: '1.125rem' }}
                className="text-blocked rounded-12 border-blocked py-2 px-8"
                variant="outlined"
                component={NextLink}
                href={'/login'}
            >
                Это мой магазин
            </Button>
            <NextLink className=" absolute bottom-0" href="http://ecommy.ru" >
                <img src={"/assets/showcase/images/logo.svg"} className="w-48 h-16" alt='LOGO'/>
            </NextLink>
        </div>
    )
}