import * as React from "react";
import { useSnackbar } from 'notistack';
import { useRouter } from 'next/router'
import CircularProgress from '@mui/material/CircularProgress';
import ChatFormUploadImage from "../../ChatFormUploadImage";
import { axiosTemplate } from "../../../../../lib/fetchJson";
import BannerDialog from "./BannerDialog";
import BannerSlider from "./BannerSlider";

export default function BannerPanel({ user, shop, product, activeCat, colorScheme }){
    const router = useRouter();
    const { enqueueSnackbar } = useSnackbar();
    const swiperRef = React.useRef(null);
    const inputRef = React.useRef();
    const [imageTemp, setImageTemp] = React.useState(null);
    const [images, setImages] = React.useState([]);
    const [bannerImages, setBannerImages] = React.useState([]);
    const [loadingUpload, setLoadingUpload] = React.useState(false);
    const [deleteLoading, setDeleteLoading] = React.useState({ id: null })
    const [hideLoading, setHideLoading] = React.useState({ id: null })
    const [open, setOpen] = React.useState(false);
    const [openHide, setOpenHide] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true)
    }
    const handleClose = () => {
        setOpen(false)
    }

    const handleClickOpenHide = () => {
        setOpenHide(true)
    }
    const handleCloseHide = () => {
        setOpenHide(false)
    }

    async function sendDel(ban){
        handleClose()
        setDeleteLoading({ id: ban.id })
        const respDel = await axiosTemplate(`${process.env.NEXT_PUBLIC_UPLOAD_STRAPI_URL}/api/upload/files/${ban.image.id}`, "DELETE", null, true, user.token)
        if(respDel.data){
            let data = {
                banners: bannerImages.filter((e)=> e.id !== ban.id)
            } 
            await axiosTemplate(`${process.env.NEXT_PUBLIC_UPLOAD_STRAPI_URL}/api/users/${user.id}`, "PUT", data, true, user.token)
            const indexChange = bannerImages.findIndex((e)=> e.id === ban.id)
            if(indexChange > -1){
                enqueueSnackbar(`Баннер ${indexChange+1} удален`, { variant: "success" });
            }
            setBannerImages(bannerImages.filter((e)=> e.id !== ban.id)) 
        }
        setDeleteLoading({ id: null })
    }

    async function sendHide(ban){
        handleCloseHide()
        setHideLoading({ id: ban.id })
        const indexChange = bannerImages.findIndex((e)=> e.id === ban.id)
        if(indexChange > -1){
            let bannersTemp = bannerImages
            bannersTemp[indexChange].is_show = !ban.is_show
            let data = {
                banners: bannersTemp
            } 
            const response = await axiosTemplate(`${process.env.NEXT_PUBLIC_UPLOAD_STRAPI_URL}/api/users/${user.id}`, "PUT", data, true, user.token)
            setBannerImages(bannersTemp)
            enqueueSnackbar(`Баннер ${indexChange+1} ${!ban.is_show ? 'скрыт' : 'показан' }`, { variant: "success" });
        }
        setHideLoading({ id: null })
    }

    React.useEffect(()=>{
        if(user && user.banners){
            setBannerImages(user.banners)
            setLoadingUpload(false)
        }
    }, [user])

    async function upload(files){
        setLoadingUpload(true)
        if(files.length > 0){
            const formData = new FormData();
            Array.from(files).forEach((image) => {
                formData.append("files", image);
            });
            const responseImage = await axiosTemplate(`${process.env.NEXT_PUBLIC_UPLOAD_STRAPI_URL}/api/upload`, "POST", formData, true, user.token)
            if(responseImage.data){
                let data = {
                    banners: bannerImages
                }
                responseImage.data.forEach(async (upload)=> {
                    bannerImages.push({ is_show: true, image: upload.id })   
                })    
                const response = await axiosTemplate(`${process.env.NEXT_PUBLIC_UPLOAD_STRAPI_URL}/api/users/${user.id}`, "PUT", data, true, user.token)
                //mutateUser()
                await router.replace(router.asPath)
            }else{
                setLoadingUpload(false)
            }
        }else{
            setLoadingUpload(false)
        }
        
    }

    function getIndex(){
        if(imageTemp){
            const indexChange = bannerImages.findIndex((e)=> e.id === imageTemp.id)
            if(indexChange > -1){
                return indexChange + 1
            }
        }
        return 1
    }
    
    if(activeCat || product){
        return <></>
    }
    
    return(
        <div>
            { user && user.isLoggedIn && (
                <>
                    <ChatFormUploadImage
                        inputRef={inputRef}
                        onChange={async (filesArray) => {
                            if(!filesArray.error){
                                await upload(filesArray.files.target.files)
                            }
                        }}
                        images={images}
                        imagesUploaded={bannerImages.length > 0 || images.length > 0}
                        countFilesIn={bannerImages.length}
                        accept="image/jpeg, image/png, image/webp"
                        buttonType={3}
                        keyId={1}
                        multiple={true}
                        maxFiles={5}
                        maxSize={2097152}
                        maxLabel="2 Mb"
                        minWidth={1200}
                        minHeight={400}
                        loadingUpload={loadingUpload}
                    />
                    <BannerDialog
                        open={open}
                        handleClose={handleClose}
                        openHide={openHide}
                        handleCloseHide={handleCloseHide}
                        getIndex={getIndex}
                        imageTemp={imageTemp}
                        sendDel={sendDel}
                        sendHide={sendHide}
                    />
                </>
            )}
            <BannerSlider
                swiperRef={swiperRef} 
                user={user} 
                bannerImages={bannerImages} 
                hideLoading={hideLoading}  
                deleteLoading={deleteLoading}  
                shop={shop} 
                handleClickOpen={handleClickOpen}  
                handleClickOpenHide={handleClickOpenHide}  
                setImageTemp={setImageTemp} 
                colorScheme={colorScheme}
            />
        </div>
    )
}
