import * as React from "react";
import { useRouter } from 'next/router'
import clsx from "clsx";
import NextLink from "next/link";
import useMediaQuery from "@mui/material/useMediaQuery";
import {ButtonPink} from "@/components/showcase/_controls";
import {getLegalName, getThemeColors} from "@/components/showcase/_helpers";
import { axiosTemplate } from "../../../lib/fetchJson";
import { useSWRConfig } from 'swr'

export default function Footer(params){
    const { cache, mutate } = useSWRConfig()
    const router = useRouter();
    const user = params.user
    const themeColor = getThemeColors(params.userInfo ? params.userInfo.shopColor : 0);
    
    async function exit(){
        const response = await axiosTemplate(`/api/logout`, "POST", null, true, null)
        cache.delete('/api/user')
        await router.replace('/')
    }

    return(
        <div className={clsx(params.className,'w-full')}>
            <div className='w-full px-3 bg-white-1 tablet:bg-white-3'>
                <div className='flex flex-col-reverse tablet:flex-row max-w-750 mx-auto mb-4'>
                    <div className='w-full tablet:w-1/2'>
                        <div className={clsx(params.userInfo && params.userInfo.shopDescriptionFull ? 'mt-0' : 'mt-6', 'tablet:mt-8 mb-4 font-bebas font-bold text-3xl tablet:text-32px')}>
                            Контакты
                        </div>
                        {params.userInfo && params.userInfo.name && (
                            <div className='mb-1.5 tablet:mb-2 text-sm tablet:text-base'>
                                {getLegalName(params.userInfo)}
                            </div>
                        )}
                        {params.userInfo && params.userInfo.phone && (
                            <div className='mb-1.5 tablet:mb-2 text-sm tablet:text-base'>
                                {params.userInfo.phone}
                            </div>
                        )}
                        <div className='mb-1.5 tablet:mb-2 text-sm tablet:text-base'>
                            {params.userInfo && params.userInfo.email ? params.userInfo.email : 'Ваш e-mail'}
                        </div>
                        {params.userInfo && params.userInfo.address && (
                            <div className='mb-2 text-sm tablet:text-base'>
                                {params.userInfo.address}
                            </div>
                        )}
                        {user && user.isLoggedIn && !params.userInfo.address && !params.userInfo.phone && !params.userInfo.vk && (
                            <div className={'flex flex-col'}>
                                <div className='max-w-md mb-5 text-gray-9 leading-normal'>
                                    Если вы хотите указать другие свои контакты (телефон, мессенджеры, соцсети), добавьте их в настройках магазина в разделе "Контакты".
                                </div>
                                <ButtonPink href={'/showcase/contacts'} component={NextLink} className='w-74 h-12 bg-gradient-to-r from-pink-1 to-purple-1 hover:brightness-90'>Добавить контакты</ButtonPink>
                            </div>
                        )}
                        {params.userInfo && (
                            <div className='flex flex-row pt-3 mb-5 space-x-5'>
                                {params.userInfo.phone && params.userInfo.whatsapp && (
                                    <NextLink href={'https://wa.clck.bar/'+params.userInfo.phone.replace('+','')} target={'_blank'} title='Whatsapp' style={{backgroundColor: themeColor}} className={'flex h-10 w-10 items-center justify-center rounded-full'}>
                                        <img src='/assets/showcase/images/logo-whatsapp.svg' alt='whatsapp'/>
                                    </NextLink>
                                )}
                                {params.userInfo.phone && params.userInfo.viber && (
                                    <NextLink href={'viber://chat?number=%2B'+params.userInfo.phone.replace('+','')} target={'_blank'} title='Viber' style={{backgroundColor: themeColor}} className={'flex h-10 w-10 items-center justify-center rounded-full'}>
                                        <img src='/assets/showcase/images/logo-viber.svg' alt='viber'/>
                                    </NextLink>
                                )}
                                {params.userInfo.phone && params.userInfo.telegram && (
                                    <NextLink href={params.userInfo.phone.startsWith('+') ? 'https://t.me/'+params.userInfo.phone : 'https://t.me/+'+params.userInfo.phone} target={'_blank'} title='Telegram' style={{backgroundColor: themeColor}} className={'flex h-10 w-10 items-center justify-center rounded-full'}>
                                        <img src='/assets/showcase/images/logo-telegram.svg' alt='telegram'/>
                                    </NextLink>
                                )}
                                {params.userInfo.vk && (
                                    <NextLink href={'https://'+params.userInfo.vk} target={'_blank'} title='ВКонтакте' style={{backgroundColor: themeColor}} className={'flex h-10 w-10 items-center justify-center rounded-full'}>
                                        <img src='/assets/showcase/images/logo-vk.svg' alt='vk'/>
                                    </NextLink>
                                )}
                            </div>
                        )}
                        {!params.productPage && (
                            <>
                                {user && user.isLoggedIn ? (
                                    <div className='flex tablet:hidden mb-4 text-gray-3 text-sm tablet:text-base cursor-pointer'
                                         onClick={exit}
                                    >
                                        Выйти
                                    </div>
                                ) : (
                                    <NextLink href='/login' passHref className='flex tablet:hidden mb-4 text-gray-3 text-sm tablet:text-base'>Войти</NextLink>
                                )}
                            </>
                        )}
                    </div>
                    {params.userInfo && params.userInfo.shopDescriptionFull && (
                        <div className='w-full tablet:w-1/2'>
                            <div className='mt-6 tablet:mt-8 mb-4 font-bebas font-bold text-3xl tablet:text-32px'>
                                О магазине
                            </div>
                            <div className='laptop:w-116 mb-7 text-black text-sm tablet:text-base leading-normal break-words'>
                                {params.userInfo && params.userInfo.shopDescriptionFull ? params.userInfo.shopDescriptionFull : 'Подробное описание вашего магазина'}
                            </div>
                        </div>
                    )}
                </div>
            </div>
            <div className={clsx(params.productPage && 'hidden tablet:block', 'w-full px-3')}>
                <div className='flex flex-col max-w-750 mx-auto'>
                    <div className='flex flex-row w-full items-center justify-between'>
                        <NextLink href='https://ecommy.ru' className='cursor-pointer'>
                            <img src='/assets/showcase/images/logo.svg' className="w-48 h-10 mt-3" alt='LOGO'/>
                        </NextLink>
                        {user && user.isLoggedIn ? (
                            <div className='hidden tablet:flex text-gray-3 text-sm tablet:text-base cursor-pointer'
                                 onClick={exit}
                            >
                                Выйти
                            </div>
                        ) : (
                            <NextLink href='/login' passHref className='hidden tablet:flex text-gray-3 text-sm tablet:text-base'>Войти</NextLink>
                        )}
                    </div>
                    <div className='flex flex-col tablet:flex-row w-full mb-6 justify-between'>
                        <div className='mb-3 tablet:mb-0 pr-4 text-sm tablet:text-base'>Интернет-магазины в аренду для бизнеса любого масштаба</div>
                        <div className='text-sm tablet:text-base'>2024</div>
                    </div>
                </div>
            </div>
        </div>
    )
}